import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dropboxToken: null,
};

const dropboxSlice = createSlice({
  name: "dropbox",
  initialState,
  reducers: {
    dropboxLoginReq: () => {},
    dropboxTokenUpdate: (state, { payload: { dropboxToken } }) => {
      state.dropboxToken = dropboxToken;
      return state;
    },
    dropboxTokenLoad: (state, { payload: { dropboxToken } }) => {
      state.dropboxToken = dropboxToken;
      return state;
    },
    dropboxTokenReset: (state) => {
      state = initialState;
      return state;
    },
  },
});

const { actions, reducer } = dropboxSlice;

export const {
  dropboxLoginReq,
  dropboxTokenUpdate,
  dropboxTokenLoad,
  dropboxTokenReset,
} = actions;

export default reducer;
