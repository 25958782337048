import { combineReducers } from "redux";

import conferenceReducer from "./jitsi/conference.slice";
import tracksReducer from "./jitsi/tracks.slice";
import userReducer from "./jitsi/user.slice";
import commandsReducer from "./jitsi/commands.slice";
import dropboxReducer from "./dropbox/dropbox.slice";
import uiReducer from "./ui/ui.slice";
import recordingReducer from "./jitsi/recording.slice";
import chatReducer from "./chat/chat.slice";
import calendarReducer from "./calendar/calendar.slice";

export const rootReducer = combineReducers({
  conferenceReducer,
  userReducer,
  dropboxReducer,
  tracksReducer,
  uiReducer,
  commandsReducer,
  recordingReducer,
  chatReducer,
  calendarReducer,
});
