import React from "react";

import styles from "./CustomSpinner.module.scss";

const CustomSpinner = ({ type, className }) => {
  const Spinner = () => {
    const SpinnerCircle = () => {
      return (
        <div
          className={`${styles.spinner} ${styles.circle} ${styles[className]}`}
        >
          {" "}
        </div>
      );
    };

    const SpinnerTiles = () => {
      return (
        <div className={`${styles.spinner} ${styles.tiles}`}>
          <div className={`${styles.rect} ${styles.rect1}`}></div>
          <div className={`${styles.rect} ${styles.rect2}`}></div>
          <div className={`${styles.rect} ${styles.rect3}`}></div>
          <div className={`${styles.rect} ${styles.rect4}`}></div>
          <div className={`${styles.rect} ${styles.rect5}`}></div>
        </div>
      );
    };

    switch (type) {
      case "circle":
        return <SpinnerCircle />;
      case "tiles":
        return <SpinnerTiles />;
      default:
        return <SpinnerCircle />;
    }
  };

  return <Spinner />;
};

export default CustomSpinner;
