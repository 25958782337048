import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n";

import serviceWorker from "./serviceWorker";
import store from "./store";

import "./styles/normalize.scss";
import "./index.scss";
import "./styles/Notifications.scss";
import "react-toastify/dist/ReactToastify.minimal.css";

import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/it";

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = dayjs.tz.guess();
dayjs.locale("it");
dayjs.tz.setDefault(tz);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker({
  onSuccess: () => store.dispatch({ type: "SW_INIT" }),
  onUpdate: (reg) => store.dispatch({ type: "SW_UPDATE", reg }),
});
