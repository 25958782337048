import React, { Suspense, useEffect, useRef, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { Router, Switch, Route, useLocation, Prompt } from "react-router-dom";
import routes, { browserHistory } from "./router/config";
import store from "./store";
import { brand } from "./config";

import { ToastContainer } from "react-toastify";

import CustomSpinner from "./components/customSpinner/CustomSpinner";
import { ChakraProvider } from "@chakra-ui/react";

import "simplebar/dist/simplebar.min.css";
import { theme } from "./theme";

function handleDropboxRedirect(hash) {
  const tokenFromStorage = JSON.parse(localStorage.getItem("dropboxToken"));

  if (!tokenFromStorage || !tokenFromStorage.access_token) {
    console.log("Dropbox not yet registered", hash);
    const params = new URLSearchParams(hash.slice(1));

    let dropboxParams = {};
    for (let key of params.keys()) {
      const param = params.get(key);

      dropboxParams[key] = param;
    }

    if (tokenFromStorage?.error_description && dropboxParams.error) {
      localStorage.setItem("dropboxToken", '{"error": "access_denied"}');
    } else {
      localStorage.setItem("dropboxToken", JSON.stringify(dropboxParams));
    }
  } else {
    console.log("dropboxToken already present in local storage");
  }
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const CustomRoute = ({ component: Component, path, exact }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (
        <>
          <ScrollToTop />
          <Component {...props} />
        </>
      )}
    />
  );
};

const App = () => {
  // Handle dropboxRedirect once
  const locationHash = window.location.hash;
  useEffect(() => {
    if (locationHash) {
      handleDropboxRedirect(locationHash);
    }
  }, [locationHash]);

  useEffect(() => {
    const favicon = document.getElementById("favicon");

    favicon.href = brand.favicon;
  }, []);

  // window.onbeforeunload = () => true;

  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        {/* <Initialization /> */}
        <Router history={browserHistory}>
          <Suspense
            fallback={
              <span className="spinner-page">
                <CustomSpinner />
              </span>
            }
          >
            <Switch>
              {routes.map((route, i) => {
                return (
                  <CustomRoute
                    key={i}
                    exact
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
            </Switch>
          </Suspense>
        </Router>
        <ToastWrapper />
      </ChakraProvider>
    </Provider>
  );
};

export default App;

function ToastWrapper(props) {
  const [show, setShow] = useState(true);

  const statsCurrentId = window.room?._statsCurrentId;

  useEffect(() => {
    if (window.room?._statsCurrentId !== "jibri") {
      setShow(true);
    } else {
      setShow(false);
    }
    return () => {};
  }, [statsCurrentId, setShow]);

  return (
    <>
      {show ? (
        <ToastContainer
          closeButton={true}
          position="bottom-left"
          autoClose={5000}
        />
      ) : (
        ""
      )}
    </>
  );
}
