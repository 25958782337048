import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatMessages: [],
  unreadMessages: 0,
  privateMessageRecipient: null,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    messageReceived: (state, { payload: { message } }) => {
      state.chatMessages.push({
        ...message,
        isPrivate: false,
      });
      return state;
    },
    privateMessageReceived: (
      state,
      { payload: { message, sender, recipient, to } }
    ) => {
      state.chatMessages.push({
        ...message,
        isPrivate: true,
        sender: sender,
        recipient: recipient,
        to: to,
      });
      return state;
    },
    sendChatMessageReq: (state, { payload: { message, to } }) => {
      return state;
    },
    sendChatMessageOk: (
      state,
      { payload: { userId, sender, recipient, message, to, type } }
    ) => {
      if (to) {
        state.chatMessages.push({
          from: userId,
          message: message,
          type: type,
          time: Date.now(),
          sender: sender || userId,
          recipient: recipient || to,
          to: to,
          isPrivate: true,
        });
      }

      return state;
    },
    sendChatMessageFail: (state, { payload: { message, to } }) => {
      return state;
    },
    sendChatMessageFailHidden: () => {},
    increaseUnreadMessages: (state) => {
      state.unreadMessages += 1;
      return state;
    },
    resetUnreadMessages: (state) => {
      state.unreadMessages = 0;
      return state;
    },
    setPrivateMessageRecipient: (state, { payload: { to } }) => {
      state.privateMessageRecipient = to;
      return state;
    },
  },
});

const { actions, reducer } = chatSlice;

export const {
  messageReceived,
  privateMessageReceived,
  sendChatMessageReq,
  sendChatMessageOk,
  sendChatMessageFail,
  increaseUnreadMessages,
  resetUnreadMessages,
  setPrivateMessageRecipient,
  sendChatMessageFailHidden,
} = actions;

export default reducer;
