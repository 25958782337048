export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const detachTrack = (track, containerId) => {
  if (track && !track.missingTrack) {
    track.detach(window.$(`#${containerId}`)[0]);
  }
};

export const getParticipant = (track) => {
  let participant;

  if (track && window.room) {
    if (track.isLocal()) {
      participant = window.room.myUserId();
    } else {
      participant = track.getParticipantId();
    }
  }

  return participant;
};

export const parseURLQuery = (query) => {
  const urlParams = new URLSearchParams(query);
  const params = urlParams.entries();

  let entries = {};

  for (const entry of params) {
    entries[entry[0]] = entry[1];
  }

  return entries;
};

export const reorderArray = (arr, from, to, els = 1) => {
  let array = [...arr];
  array.splice(to, 0, array.splice(from, els)[0]);
  return array;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Updates target [key] array by adding [value] to it.
 * Works fine with non existing arrays too.
 * @param {string} key
 * @param {any} value
 */
export const localStorageAddItemToArray = (key, value) => {
  let array = [];
  const localData = JSON.parse(window.localStorage.getItem(key));
  localData?.length && (array = array.concat(localData));

  array.push(value);

  window.localStorage.setItem(key, JSON.stringify(array));
};

/**
 * Updates target [key] array by changing [index] element with [value].
 * If given the fourth param [findCallback(array)], [index] will be ignored and [findCallback(array)] will return the index
 * for the element you wnat to update.
 * @param {string} key
 * @param {any} value
 * @param {number} index
 * @param {function} findCallback
 */
export const localStorageUpdateItemInArray = (
  key,
  value,
  index,
  findCallback
) => {
  let array = [];
  const localData = JSON.parse(window.localStorage.getItem(key));
  localData?.length && (array = array.concat(localData));

  if (findCallback) {
    const i = findCallback(array);

    array[i] = value;

    window.localStorage.setItem(key, JSON.stringify(array));

    return;
  }
  array[index] = value;

  window.localStorage.setItem(key, JSON.stringify(array));
};
export const getRecordingDurationEstimation = (size) => {
  return Math.floor((size || 0) / 10);
};

export const isEmptyObject = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getUserNameInitials = (name, chars) => {
  if (name) {
    return name.slice(0, chars).toUpperCase();
  }
};

export const getFileNameWithExt = (event) => {
  if (
    !event ||
    !event.target ||
    !event.target.files ||
    event.target.files.length === 0
  ) {
    return;
  }

  const name = event.target.files[0].name;
  const lastDot = name.lastIndexOf(".");

  const fileName = name.substring(0, lastDot);
  const ext = name.substring(lastDot + 1);

  return { fileName, ext };
};

export const getFileSizeInMb = (event) => {
  return event.target.files[0].size / 1024 / 1024;
};

export const gup = (url, name) => {
  // eslint-disable-next-line
  name = name.replace(/[[]/, "[").replace(/[]]/, "]");
  const regexS = "[?&]" + name + "=([^&#]*)";
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  if (results == null) return "";
  else return results[1];
};

export const arrayUnique = (array) => {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
};

export const getHoursCount = (milliseconds) => {
  return Math.floor(milliseconds / (60 * 60 * 1000));
};

export const getMinutesCount = (milliseconds) => {
  return Math.floor((milliseconds / (60 * 1000)) % 60);
};

export const getSecondsCount = (milliseconds) => {
  return Math.floor((milliseconds / 1000) % 60);
};

export const isInArray = (value, array) => {
  return array.indexOf(value) > -1;
};

export const debounce = (func, wait, options) => {
  let lastArgs,
    lastThis,
    maxWait,
    result,
    timerId,
    lastCallTime,
    lastInvokeTime = 0,
    leading = false,
    maxing = false,
    trailing = true;
  if (typeof func !== "function") {
    throw new TypeError("not a function");
  }
  wait = Number(wait) || 0;
  if (typeof options === "object") {
    leading = !!options.leading;
    maxing = "maxWait" in options;
    maxWait = maxing ? Math.max(Number(options.maxWait) || 0, wait) : maxWait;
    trailing = "trailing" in options ? !!options.trailing : trailing;
  }

  const invokeFunc = (time) => {
    let args = lastArgs,
      thisArg = lastThis;

    lastArgs = lastThis = undefined;
    lastInvokeTime = time;
    result = func.apply(thisArg, args);
    return result;
  };

  const leadingEdge = (time) => {
    // Reset any `maxWait` timer.
    lastInvokeTime = time;
    // Start the timer for the trailing edge.
    timerId = setTimeout(timerExpired, wait);
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result;
  };

  const remainingWait = (time) => {
    let timeSinceLastCall = time - lastCallTime,
      timeSinceLastInvoke = time - lastInvokeTime,
      result = wait - timeSinceLastCall;
    return maxing ? Math.min(result, maxWait - timeSinceLastInvoke) : result;
  };

  const shouldInvoke = (time) => {
    let timeSinceLastCall = time - lastCallTime,
      timeSinceLastInvoke = time - lastInvokeTime;
    // Either this is the first call, activity has stopped and we're at the trailing
    // edge, the system time has gone backwards and we're treating it as the
    // trailing edge, or we've hit the `maxWait` limit.
    return (
      lastCallTime === undefined ||
      timeSinceLastCall >= wait ||
      timeSinceLastCall < 0 ||
      (maxing && timeSinceLastInvoke >= maxWait)
    );
  };

  const timerExpired = () => {
    const time = Date.now();
    if (shouldInvoke(time)) {
      return trailingEdge(time);
    }
    // Restart the timer.
    timerId = setTimeout(timerExpired, remainingWait(time));
  };

  const trailingEdge = (time) => {
    timerId = undefined;

    // Only invoke if we have `lastArgs` which means `func` has been debounced at
    // least once.
    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    lastArgs = lastThis = undefined;
    return result;
  };

  const cancel = () => {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    lastInvokeTime = 0;
    lastArgs = lastCallTime = lastThis = timerId = undefined;
  };

  const flush = () => {
    return timerId === undefined ? result : trailingEdge(Date.now());
  };

  function debounced() {
    let time = Date.now(),
      isInvoking = shouldInvoke(time);
    lastArgs = arguments;
    lastThis = this;
    lastCallTime = time;

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = setTimeout(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === undefined) {
      timerId = setTimeout(timerExpired, wait);
    }
    return result;
  }
  debounced.cancel = cancel;
  debounced.flush = flush;
  return debounced;
};

export const throttle = (func, wait, options) => {
  let leading = true,
    trailing = true;

  if (typeof func !== "function") {
    throw new TypeError("not a function");
  }
  if (typeof options === "object") {
    leading = "leading" in options ? !!options.leading : leading;
    trailing = "trailing" in options ? !!options.trailing : trailing;
  }
  return debounce(func, wait, {
    leading,
    maxWait: wait,
    trailing,
  });
};

export const isUserInLobby = (user) => user.getProperty("inLobby");

export const getParticipiantsInsideRoom = (
  participants,
  participantsDetails
) => {
  const participantsInRoom = participants.filter(
    (participant) =>
      !participantsDetails[participant]?.inLobby &&
      !participantsDetails[participant]?.isJibri
  );

  return participantsInRoom.map(
    (p) => participantsDetails[p]?.displayName ?? participantsDetails[p]?.id
  );
};

export const checkIfJibri = (locationHash) => {
  if (!locationHash) {
    return false;
  }

  const parsedHash = parseURLQuery(locationHash.slice(1));

  return parsedHash?.["config.iAmRecorder"] === "true";
};
