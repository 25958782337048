import { combineEpics } from "redux-observable";
import { of, merge } from "rxjs";
import {
  filter,
  concatMap,
  mergeMap,
  map,
  takeUntil,
  exhaustMap,
  take,
} from "rxjs/operators";

import {
  initializeRoomOk,
  enterRoomOk,
  conferenceUserJoined,
  conferenceUserLeft,
  conferenceDisconnect,
  changeNameOk,
  changeNameFail,
  conferenceConnectionInterrupted,
  connectionFailed,
  conferenceFailed,
  startCountDown,
  stopCountDown,
  conferenceConnectionRestored,
  setLobbyLockedOk,
  setLobbyLockedFail,
  conferenceKicked,
  enableConferencePasswordOk,
  disableConferencePasswordOk,
  toggleConferencePassword,
  joinRequestReceived,
  joinRequestAccepted,
  joinRequestDenied,
  sendJoinRequestOk,
  sendJoinRequestFail,
} from "./conference.slice";

import {
  startRecordingOk,
  startRecordingFail,
  stopRecordingOk,
  stopRecordingFail,
} from "./recording.slice";

import {
  toggleScreenSharingOk,
  unmuteRequested,
  toggleTrackOk,
  toggleDeviceOnScreenSharing,
  resetUnmuteRequest,
  permissionsError,
  setPresenterEffectFail,
  muteCamToShareScreen,
  unMuteToShareScreen,
} from "./tracks.slice";

import {
  messageReceived,
  privateMessageReceived,
  sendChatMessageFailHidden,
} from "../chat/chat.slice";

import { jitsiInitOk } from "./user.slice";

const AUTO_CLOSE = 3000;

/**
 * @description User joined notification
 */
const userJoinedNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(conferenceUserJoined.match),
        mergeMap(({ payload: { userId } }) => {
          const myUserId = state$.value.conferenceReducer.userId;

          if (userId !== myUserId) {
            toast(i18n.t("notification_new_user_lobby"), {
              autoClose: AUTO_CLOSE,
            });
          }

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description User left notification
 */
const userLeftNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(conferenceUserLeft.match),
        mergeMap(({ payload: { userId } }) => {
          const myUserId = state$.value.conferenceReducer.userId;

          if (userId !== myUserId) {
            toast(i18n.t("notification_user_left"), {
              autoClose: AUTO_CLOSE,
            });
          }

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Display name change success notification
 */
const displayNameChangedSuccessNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(enterRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(changeNameOk.match),
        mergeMap(({ payload: { displayName } }) => {
          toast(i18n.t("notification_display_name_change_success"), {
            autoClose: AUTO_CLOSE,
          });

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Display name change failed notification
 */
const displayNameChangedFailedNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(changeNameFail.match),
        mergeMap(({ payload: { displayName } }) => {
          toast.error(i18n.t("notification_display_name_change_failed"), {
            autoClose: AUTO_CLOSE,
          });

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Connection failed notification
 */
const connectionRoomFailedNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(conferenceFailed.match),
    mergeMap(({ payload: { error } }) => {
      if (error === "conference.passwordRequired") {
        toast(i18n.t("notification_password_required"), {
          autoClose: AUTO_CLOSE,
        });
      } else if (error === "conference.connectionError.notAllowed") {
        toast.error(i18n.t("notification_connection_error_not_allowed"), {
          autoClose: AUTO_CLOSE,
        });
      } else {
        toast.error(i18n.t("notification_connection_failed"), {
          autoClose: AUTO_CLOSE,
        });
      }

      return [];
    })
  );

/**
 * @description Connection interrupted notification
 */
const connectionInterruptedNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(conferenceConnectionInterrupted.match),
        mergeMap(({ payload: { roomName } }) => {
          toast.error(i18n.t("notification_connection_lost"), {
            autoClose: AUTO_CLOSE,
          });

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Connection restored notification
 */
const connectionRestoredNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(conferenceConnectionRestored.match),
        mergeMap(({ payload: { roomName } }) => {
          toast(i18n.t("notification_connection_reestablished"), {
            autoClose: AUTO_CLOSE,
          });

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Lock change notification
 */
const lockChangeSuccessNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(setLobbyLockedOk.match),
        mergeMap(({ payload: { roomName } }) => {
          const isModerator = state$.value.conferenceReducer.isModerator;
          const isLobbyLocked = state$.value.conferenceReducer.isLobbyLocked;
          if (isModerator)
            toast(
              i18n.t(
                isLobbyLocked
                  ? "notification_room_lobby_locked"
                  : "notification_room_lobby_unlocked"
              ),
              {
                autoClose: AUTO_CLOSE,
              }
            );
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Lock change fail notification
 */
const lockChangeFailNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(setLobbyLockedFail.match),
        mergeMap(() => {
          const isModerator = state$.value.conferenceReducer.isModerator;
          if (isModerator)
            toast.error(i18n.t("notification_room_lobby_switch_lock_failed"), {
              autoClose: AUTO_CLOSE,
            });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description User kicked notification
 */
const userKickedNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(conferenceKicked.match),
        mergeMap(({ payload: { userId } }) => {
          toast(i18n.t("notification_kicked"), {
            autoClose: AUTO_CLOSE,
          });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Room password toggle lock notification
 */
const togglePasswordNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(toggleConferencePassword.match),
        mergeMap(({ payload: { password } }) => {
          const isModerator = state$.value.conferenceReducer.isModerator;

          if (!!password?.length && isModerator) {
            toast(i18n.t("notification_lobby_password_enabled"), {
              autoClose: AUTO_CLOSE,
            });
          }
          if (!password?.length && isModerator) {
            toast(i18n.t("notification_lobby_password_disabled"), {
              autoClose: AUTO_CLOSE,
            });
          }

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Room join request received notification
 */
const joinRequestReceivedNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(joinRequestReceived.match),
        mergeMap(() => {
          const isModerator = state$.value.conferenceReducer.isModerator;
          if (isModerator)
            toast(i18n.t("notification_join_request_received"), {
              autoClose: AUTO_CLOSE,
            });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Room join request accepted notification
 */
const joinRequestAcceptedNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(joinRequestAccepted.match),
        mergeMap(() => {
          toast(i18n.t("notification_join_request_accepted"), {
            autoClose: AUTO_CLOSE,
          });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Room join request denied notification
 */
const joinRequestDeniedNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(joinRequestDenied.match),
        mergeMap(() => {
          toast(i18n.t("notification_join_request_denied"), {
            autoClose: AUTO_CLOSE,
          });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Room join request send notification
 */
const joinRequestSendSuccessNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(sendJoinRequestOk.match),
        mergeMap(() => {
          toast(i18n.t("notification_join_request_send_success"), {
            autoClose: AUTO_CLOSE,
          });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Room join request send failed notification
 */
const joinRequestSendFailedNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(sendJoinRequestFail.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_join_request_send_failed"), {
            autoClose: AUTO_CLOSE,
          });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Conference start recording success notification
 */
const startRecordingSuccessNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(startRecordingOk.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_start_recording_success"), {
            autoClose: AUTO_CLOSE,
          });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Conference start recording failed notification
 */
const startRecordingFailedNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(startRecordingFail.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_start_recording_failed"), {
            autoClose: AUTO_CLOSE,
          });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Conference stop recording success notification
 */
const stopRecordingSuccessNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(stopRecordingOk.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_stop_recording_success"), {
            autoClose: AUTO_CLOSE,
          });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Conference stop recording failed notification
 */
const stopRecordingFailedNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(stopRecordingFail.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_stop_recording_failed"), {
            autoClose: AUTO_CLOSE,
          });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Screen sharing notification
 */
const screenSharingNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(toggleScreenSharingOk.match),
        mergeMap(() => {
          const isSharing = state$.value.tracksReducer.isScreenSharing;

          if (isSharing)
            toast(i18n.t("notification_screen_sharing_on"), {
              autoClose: AUTO_CLOSE,
            });
          if (!isSharing)
            toast(i18n.t("notification_screen_sharing_off"), {
              autoClose: AUTO_CLOSE,
            });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Change device on screen sharing notification
 */
const changeDeviceDuringScreenShareNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(toggleDeviceOnScreenSharing.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_change_device_on_sharing"), {
            autoClose: AUTO_CLOSE,
          });

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Switch cam on screen sharing notification
 */
const setPresenterEffectFailNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(setPresenterEffectFail.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_presenter_effect_fail"), {
            autoClose: AUTO_CLOSE,
          });

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Screen sharing can't be started with cam opened on safari notification
 */
const setMuteCamToShareScreenNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(muteCamToShareScreen.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_mute_cam_share"), {
            autoClose: AUTO_CLOSE,
          });

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Screen sharing starts with mute and audio share notification
 */
const setAudioMixEffectFailNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];

      return action$.pipe(
        filter(unMuteToShareScreen.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_audio_effect_fail"), {
            autoClose: AUTO_CLOSE,
          });

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Message received notification
 */
const messageReceivedNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      let notification = null;
      return action$.pipe(
        filter(messageReceived.match),
        mergeMap(({ payload: { message } }) => {
          const myUserId = state$.value.conferenceReducer.userId;

          if (message.from === myUserId) {
            return [];
          }

          if (!notification)
            notification = toast(i18n.t("notification_message_received"), {
              autoClose: AUTO_CLOSE,
            });

          if (notification) {
            toast.update(notification, { autoClose: AUTO_CLOSE });
          }

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Private message received notification
 */
const privateMessageReceivedNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      let notification = null;
      return action$.pipe(
        filter(privateMessageReceived.match),
        mergeMap(({ payload: { message } }) => {
          const myUserId = state$.value.conferenceReducer.userId;

          if (message.from === myUserId) {
            return [];
          }

          if (!notification)
            notification = toast(i18n.t("notification_message_received"), {
              autoClose: AUTO_CLOSE,
            });

          if (notification) {
            toast.update(notification, { autoClose: AUTO_CLOSE });
          }

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Message fail send hidden user notification
 */
const messageFailSendNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() =>
      action$.pipe(
        filter(sendChatMessageFailHidden.match),
        mergeMap(() => {
          toast.error(i18n.t("notification_message_fail_hidden"), {
            autoClose: AUTO_CLOSE,
          });

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      )
    )
  );

/**
 * @description Unmute request notification
 */
const unmuteRequestedNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) return [];
      let notification;
      return action$.pipe(
        filter(
          (action) =>
            action.type === unmuteRequested.toString() && !notification
        ),
        mergeMap(({ payload: { type } }) => {
          notification = toast(i18n.t(`notification_unmute_request_${type}`), {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            closeButton: false,
          });

          return action$.pipe(
            filter(resetUnmuteRequest.match),
            mergeMap(() => {
              toast.dismiss(notification);
              notification = null;

              return [];
            }),
            take(1)
          );
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Room closing timer notification
 */
const roomClosingStartCountdownNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      let notification = null;
      return action$.pipe(
        filter(startCountDown.match),
        mergeMap(() => {
          if (!notification)
            notification = toast.error(i18n.t("notification_room_closing"), {
              autoClose: AUTO_CLOSE * 3,
            });
          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Room stop closing timer notification
 */
const roomClosingStopCountdownNotificationEpic = (
  action$,
  state$,
  { toast, i18n }
) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      return action$.pipe(
        filter(stopCountDown.match),
        mergeMap(() => {
          const imModerator = state$.value.conferenceReducer.isModerator;
          const otherModerators = Object.values(
            state$.value.conferenceReducer.participantsDetails
          ).filter(
            (participant) =>
              participant?.role === "moderator" && !participant?.inLobby
          );

          if (imModerator || !!otherModerators.length) {
            toast(i18n.t("notification_room_not_closing"), {
              autoClose: AUTO_CLOSE,
            });
          }

          if (!imModerator && !otherModerators.length) {
            toast.error(i18n.t("notification_room_closed"), {
              autoClose: AUTO_CLOSE,
            });
          }

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description user don't grant permissions notification
 */
const noPermissionsNotificationEpic = (action$, state$, { toast, i18n }) =>
  action$.pipe(
    filter(permissionsError.match),
    mergeMap(() => {
      toast.error(i18n.t("notification_no_permissions"), {
        autoClose: AUTO_CLOSE,
      });

      return [];
    })
  );

export const notificationEpic = combineEpics(
  userJoinedNotificationEpic,
  userLeftNotificationEpic,
  displayNameChangedSuccessNotificationEpic,
  displayNameChangedFailedNotificationEpic,
  connectionRoomFailedNotificationEpic,
  connectionInterruptedNotificationEpic,
  connectionRestoredNotificationEpic,
  lockChangeSuccessNotificationEpic,
  lockChangeFailNotificationEpic,
  userKickedNotificationEpic,
  togglePasswordNotificationEpic,
  joinRequestReceivedNotificationEpic,
  joinRequestAcceptedNotificationEpic,
  joinRequestDeniedNotificationEpic,
  joinRequestSendSuccessNotificationEpic,
  joinRequestSendFailedNotificationEpic,
  startRecordingSuccessNotificationEpic,
  startRecordingFailedNotificationEpic,
  stopRecordingSuccessNotificationEpic,
  stopRecordingFailedNotificationEpic,
  screenSharingNotificationEpic,
  setPresenterEffectFailNotificationEpic,
  messageReceivedNotificationEpic,
  privateMessageReceivedNotificationEpic,
  unmuteRequestedNotificationEpic,
  roomClosingStartCountdownNotificationEpic,
  roomClosingStopCountdownNotificationEpic,
  noPermissionsNotificationEpic,
  messageFailSendNotificationEpic,
  changeDeviceDuringScreenShareNotificationEpic,
  setAudioMixEffectFailNotificationEpic,
  setMuteCamToShareScreenNotificationEpic
);
