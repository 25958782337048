import { combineEpics, ofType } from "redux-observable";
import { from, of, merge } from "rxjs";
import {
  catchError,
  filter,
  mergeMap,
  concatMap,
  map,
  takeUntil,
  delay,
} from "rxjs/operators";
import { confOptions, serverOptions } from "../../config";
import {
  getCommandsObserver,
  getEmitterEventObserver,
  parsePropertyValue,
  setVideoQuality,
} from "./jitsi.utils";
import {
  conferenceConnectionInterrupted,
  conferenceConnectionRestored,
  conferenceCreatedTimestamp,
  conferenceDisconnect,
  conferenceReload,
  conferenceDisplayNameChanged,
  conferenceDominantSpeakerChanged,
  conferenceFailed,
  conferenceJoined,
  conferenceKicked,
  conferenceLockStateChanged,
  conferenceParticipantPropertyChanged,
  conferenceSubjectChanged,
  conferenceUserJoined,
  conferenceUserLeft,
  startCountDown,
  stopCountDown,
  closeRoom,
  connectionDisconnected,
  connectionEstablished,
  connectionFailed,
  enterRoomOk,
  enterRoomReq,
  initializeRoomOk,
  initializeRoomReq,
  joinRequestReceived,
  joinRequestRemoved,
  joinRequestAccepted,
  joinRequestDenied,
  sendJoinRequestOk,
  sendJoinRequestReq,
  setLobbyLockedOk,
  setLobbyLockedReq,
  acceptJoinRequest,
  denyJoinRequest,
  toggleConferencePasswordReq,
  toggleConferencePassword,
  joinRoomReq,
  joinRoomOk,
  changeNameReq,
  changeNameOk,
  changeNameFail,
  conferenceTogglePinUser,
  muteParticipantOk,
  muteParticipantFail,
  muteParticipantReq,
  kickParticipantReq,
  kickParticipantOk,
  kickParticipantFail,
  unmuteParticipantReq,
  unmuteParticipantOk,
  unmuteParticipantFail,
  toggleRoomRecording,
} from "./conference.slice";

import { uiResetDialogs } from "../ui/ui.slice";
import { unmuteRequested } from "./tracks.slice";

import {
  JOIN_REQUEST,
  JOIN_REQUEST_ACCEPTED,
  JOIN_REQUEST_DENIED,
  MUTE_PARTICIPANT_AUDIO,
  MUTE_PARTICIPANT_VIDEO,
  REQUEST_UNMUTE_PARTICIPANT_AUDIO,
  REQUEST_UNMUTE_PARTICIPANT_VIDEO,
  ROOM_CLOSE,
  ROOM_OPEN,
} from "./commands.constants";
import {
  checkIfJibri,
  localStorageAddItemToArray,
  localStorageUpdateItemInArray,
} from "../../imports/utils";
import { toggleTrackReq } from "./tracks.slice";
import { getJibriAuthCredentials } from "../../api/user/auth";

/**
 * @description Initializes the Jitsi Connection
 */
const initializeConnectionEpic = (action$, state$, { JitsiMeetJS }) =>
  action$.pipe(
    filter(initializeRoomReq.match),
    mergeMap(({ payload: { roomName } }) => {
      const options = {
        ...serverOptions,
        serviceUrl:
          serverOptions.serviceUrl + `?room=${roomName.toLowerCase()}`,
      };

      const { jwt } = state$.value.userReducer;

      if (jwt) {
        window.localStorage.setItem("jwt-token", jwt);
      }

      const connection = new JitsiMeetJS.JitsiConnection(null, jwt, options);

      //Sets the logging info to error for a more readable console
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

      const connectionEstablishedObserver = getEmitterEventObserver(
        connection,
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED
      ).pipe(
        mergeMap(() => of(connectionEstablished({ roomName, connection })))
      );

      const connectionFailedObserver = getEmitterEventObserver(
        connection,
        JitsiMeetJS.events.connection.CONNECTION_FAILED
      ).pipe(mergeMap((error) => of(connectionFailed({ error }))));

      const connectionDisconnectedObserver = getEmitterEventObserver(
        connection,
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED
      ).pipe(mergeMap(() => of(connectionDisconnected({ roomName }))));

      const jibriAuthCredentials = getJibriAuthCredentials();

      if (jibriAuthCredentials) {
        connection.connect({
          id: jibriAuthCredentials.username,
          password: jibriAuthCredentials.password,
        });
      } else {
        connection.connect();
      }

      return merge(
        connectionEstablishedObserver,
        connectionDisconnectedObserver,
        connectionFailedObserver
      ).pipe(
        takeUntil(
          action$.pipe(
            ofType(conferenceDisconnect.toString()),
            map(() => connection.disconnect())
          )
        )
      );
    })
  );

/**
 * @description Initializes room
 */
const initializeRoomEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(({ payload: { roomName, connection } }) =>
      from(roomManager.initialize(connection, roomName, confOptions)).pipe(
        delay(1500),
        mergeMap(() => {
          const room = roomManager.getRoom();

          const preferredVideoQuality =
            state$.value.userReducer.preferredVideoQuality;

          // const isJibri = room._statsCurrentId === "jibri";
          const isJibri = checkIfJibri(window.location.hash);

          let actions = [
            initializeRoomOk({
              roomName,
              userId: room.myUserId(),
              isModerator: room.isModerator(),
              isJibri,
            }),
          ];

          if (isJibri) {
            actions.push(enterRoomReq({ roomName }));
          }

          return from(setVideoQuality(room, preferredVideoQuality)).pipe(
            mergeMap(() => actions)
          );
        }),
        catchError((e) => [])
      )
    )
  );

/**
 * @description Joins the room and handles room events
 */
const roomEventsEpic = (action$, state$, { JitsiMeetJS, roomManager }) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(({ payload: { roomName } }) => {
      const room = roomManager.getRoom();

      const { isHidden } = state$.value.userReducer;

      room.setLocalParticipantProperty("inLobby", true);
      room.setLocalParticipantProperty("isHidden", isHidden);

      const conferenceFailedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.CONFERENCE_FAILED
      ).pipe(mergeMap((error) => of(conferenceFailed({ error }))));

      const conferenceJoinedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.CONFERENCE_JOINED
      ).pipe(
        mergeMap(() => {
          return of(conferenceJoined({ roomName }));
        })
      );

      const conferenceCreatedTimestampObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.CONFERENCE_CREATED_TIMESTAMP
      ).pipe(
        mergeMap((timestamp) => of(conferenceCreatedTimestamp({ timestamp })))
      );

      const conferenceSubjectChangedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.SUBJECT_CHANGED
      ).pipe(mergeMap((subject) => of(conferenceSubjectChanged({ subject }))));

      const conferenceConnectionInterruptedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.CONNECTION_INTERRUPTED
      ).pipe(mergeMap(() => of(conferenceConnectionInterrupted({ roomName }))));

      const conferenceConnectionRestoredObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.CONNECTION_RESTORED
      ).pipe(mergeMap(() => of(conferenceConnectionRestored({ roomName }))));

      const conferenceLockStateChangedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.LOCK_STATE_CHANGED
      ).pipe(mergeMap(() => of(conferenceLockStateChanged({ roomName }))));

      return merge(
        [joinRoomReq()],
        conferenceFailedObserver,
        conferenceJoinedObserver,
        conferenceCreatedTimestampObserver,
        conferenceSubjectChangedObserver,
        conferenceConnectionInterruptedObserver,
        conferenceConnectionRestoredObserver,
        conferenceLockStateChangedObserver
      ).pipe(takeUntil(action$.ofType(conferenceDisconnect.toString())));
    })
  );

const joinRoomEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(joinRoomReq.match),
    concatMap(({ payload }) => {
      const room = roomManager.getRoom();

      const { displayName } = state$.value.userReducer;

      if (payload?.password) {
        room.join(payload.password);
      } else {
        room.join();
      }

      if (displayName) {
        return [joinRoomOk(), changeNameReq({ userName: displayName })];
      }

      return [joinRoomOk()];
    })
  );

const roomFailEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(conferenceFailed.match),
    concatMap(({ payload: { error } }) => {
      if (error === "conference.passwordRequired") {
        return [];
      }
      if (error === "conference.connectionError.notAllowed") {
        return [conferenceDisconnect()];
      }
      return [];
    })
  );

const connectionFailEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(connectionFailed.match),
    concatMap(({ payload: { error } }) => {
      if ("connection.otherError") {
        return [conferenceDisconnect()];
      }
      console.log(error);
      return [];
    })
  );

const enterRoomEpic = (action$, state$, { roomManager, tracksManager }) =>
  action$.pipe(
    filter(enterRoomReq.match),
    mergeMap(({ payload: { roomName } }) => {
      const room = roomManager.getRoom();

      const localTracks = state$.value.tracksReducer.localTracks;

      if (localTracks.audio) {
        room.addTrack(tracksManager.getTrack(localTracks.audio.id));
      }

      if (localTracks.video) {
        room.addTrack(tracksManager.getTrack(localTracks.video.id));
      }

      room.setLocalParticipantProperty("inLobby", false);

      const recentRooms = JSON.parse(
        window.localStorage.getItem("recentRooms")
      );
      let index = recentRooms
        ?.map(function (e) {
          return e.name;
        })
        .indexOf(room.getName());

      if (index === -1 || (!index && index !== 0)) {
        localStorageAddItemToArray("recentRooms", {
          name: room.getName(),
          start: new Date().getTime(),
        });
      } else if (index > -1) {
        localStorageUpdateItemInArray(
          "recentRooms",
          {
            name: room.getName(),
            start: new Date().getTime(),
          },
          index
        );
      }

      let actions = [
        enterRoomOk({
          isModerator: room.isModerator(),
        }),
      ];

      const participants = state$.value.conferenceReducer.participants;
      const userId = state$.value.conferenceReducer.userId;

      if (participants.length) {
        actions.push(conferenceDominantSpeakerChanged({ userId }));
      }

      return actions;
    })
  );

const participantsEpic = (
  action$,
  state$,
  { roomManager, JitsiMeetJS, tracksManager, toast }
) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(({ payload: { roomName } }) => {
      const room = roomManager.getRoom();

      const userJoinedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.USER_JOINED
      ).pipe(
        delay(1000),
        filter(
          ([userId, _user]) => userId !== state$.value.conferenceReducer.userId
        ),
        mergeMap(([userId, user]) => {
          const properties = user?._properties;
          const { inLobby, isHidden } = properties || {};
          const isJibri = user.getStatsID() === "jibri";

          if (isJibri) {
            return [
              toggleRoomRecording({
                isRoomRecording: true,
                userId: userId,
                sessionId: user.getDisplayName(),
              }),
            ];
          }

          return [
            conferenceUserJoined({
              userId,
              userDetails: {
                displayName: user.getDisplayName() || user.getId(),
                inLobby: parsePropertyValue(inLobby),
                isHidden: parsePropertyValue(isHidden),
                isJibri,
                role: user.getRole(),
                tracks: {},
              },
            }),
          ];
        })
      );

      const userLeftObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.USER_LEFT
      ).pipe(
        mergeMap((data) => {
          const isJibri =
            state$.value.conferenceReducer.participantsDetails[data[0]]
              ?.isJibri;

          if (isJibri) {
            return [
              toggleRoomRecording({
                isRoomRecording: false,
                userId: data[0],
                sessionId: null,
              }),
            ];
          }

          return [conferenceUserLeft({ userId: data[0] })];
        })
      );

      // TODO: check data content
      const displayNameChangedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED
      ).pipe(
        mergeMap(([userId, displayName]) =>
          of(conferenceDisplayNameChanged({ userId, displayName }))
        )
      );

      const conferenceKickedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.KICKED
      ).pipe(mergeMap((data) => of(conferenceKicked({ userId: data[0] }))));

      const dominantSpeakerChangedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED
      ).pipe(
        mergeMap((userId) => {
          return of(conferenceDominantSpeakerChanged({ userId }));
        })
      );

      const participantPropertyChangedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED
      ).pipe(
        delay(2000),
        mergeMap(([user, propertyName, _, propertyValue]) => {
          const value = parsePropertyValue(propertyValue);

          return of(
            conferenceParticipantPropertyChanged({
              userId: user._id,
              propertyName,
              propertyValue: value,
            })
          );
        })
      );

      return merge(
        userJoinedObserver,
        userLeftObserver,
        displayNameChangedObserver,
        conferenceKickedObserver,
        dominantSpeakerChangedObserver,
        participantPropertyChangedObserver
      ).pipe(takeUntil(action$.ofType(conferenceDisconnect.toString())));
    })
  );

const lobbyLockEpic = (action$, _state$, { roomManager }) =>
  action$.pipe(
    filter(setLobbyLockedReq.match),
    mergeMap(({ payload: { lobbyLocked } }) => {
      const room = roomManager.getRoom();

      room.setSubject(lobbyLocked ? "lockedLobby" : "");

      return [setLobbyLockedOk({ lobbyLocked })];
    })
  );

/**
 * @description Initialize command listeners
 */
const joinRequestListenerEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(() => {
      const room = roomManager.getRoom();

      const roomName = state$.value.conferenceReducer.conferenceName;

      const userRequestingJoinObservable = getCommandsObserver(
        room,
        JOIN_REQUEST
      ).pipe(mergeMap(([_, userId]) => [joinRequestReceived({ userId })]));

      const joinRequestAcceptedObservable = getCommandsObserver(
        room,
        JOIN_REQUEST_ACCEPTED
      ).pipe(
        mergeMap(([command]) => [
          joinRequestAccepted(),
          joinRequestRemoved({ userId: command.value }),
          enterRoomReq({ roomName }),
        ])
      );

      const joinRequestDeniedObservable = getCommandsObserver(
        room,
        JOIN_REQUEST_DENIED
      ).pipe(mergeMap(() => [joinRequestDenied()]));

      return merge(
        userRequestingJoinObservable,
        joinRequestAcceptedObservable,
        joinRequestDeniedObservable
      ).pipe(takeUntil(action$.ofType(conferenceDisconnect.toString())));
    })
  );

/**
 * @description send a join request
 */
const joinRequestEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(sendJoinRequestReq.match),
    concatMap(() => {
      const room = roomManager.getRoom();

      const jwt = state$.value.userReducer.jwt;
      const userId = state$.value.conferenceReducer.userId;
      const userName = jwt?.context?.user ? jwt.context.user?.name : null;

      room.sendCommandOnce(JOIN_REQUEST, {
        value: userName,
        attributes: {
          from: userId,
        },
      });

      return of(sendJoinRequestOk());
    })
  );

/**
 * @description accept a join request
 */
const joinRequestAcceptEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(acceptJoinRequest.match),
    concatMap(({ payload: { userId } }) => {
      const room = roomManager.getRoom();

      room.sendCommandOnce(JOIN_REQUEST_ACCEPTED, {
        value: userId,
        attributes: {
          from: room.myUserId(),
        },
      });

      return of(joinRequestRemoved({ userId }));
    })
  );

/**
 * @description deny a join request
 */
const joinRequestDenyEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(denyJoinRequest.match),
    concatMap(({ payload: { userId } }) => {
      const room = roomManager.getRoom();

      room.sendCommandOnce(JOIN_REQUEST_DENIED, {
        value: userId,
        attributes: {
          from: room.myUserId(),
        },
      });

      return of(joinRequestRemoved({ userId }));
    })
  );

/**
 * @description enable password for the current room
 */
const roomTogglePasswordEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(toggleConferencePasswordReq.match),
    mergeMap(() => {
      const room = roomManager.getRoom();
      const userId = state$.value.conferenceReducer.userId;
      const isLocked = state$.value.conferenceReducer.isLocked;
      let password = null;

      if (!isLocked) {
        password = Math.random().toString(36).slice(2);

        return from(room.lock(password)).pipe(
          mergeMap(() => {
            room.sendCommandOnce(ROOM_CLOSE, {
              value: password,
              attributes: { from: userId },
            });

            return [];
          })
        );
      }

      return from(room.unlock()).pipe(
        mergeMap(() => {
          room.sendCommandOnce(ROOM_OPEN, {
            value: password,
            attributes: { from: userId },
          });

          return [];
        })
      );
    })
  );

/**
 * @description Room's password observe epic
 */
const roomPasswordObserverEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(() => {
      const room = roomManager.getRoom();

      const roomCloseObservable = getCommandsObserver(room, ROOM_CLOSE).pipe(
        mergeMap(([command]) => {
          return [toggleConferencePassword({ password: command.value })];
        })
      );

      const roomOpenObservable = getCommandsObserver(room, ROOM_OPEN).pipe(
        mergeMap(([command]) => {
          return [toggleConferencePassword({ password: command.value })];
        })
      );

      return merge(roomCloseObservable, roomOpenObservable).pipe(
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

const disconnectionEpic = (
  action$,
  state$,
  { browserHistory, tracksManager, roomManager }
) =>
  action$.pipe(
    filter(conferenceDisconnect.match),
    mergeMap(() => {
      browserHistory.replace("/");
      tracksManager.removeAllTracks();
      roomManager.destroy();
      return [uiResetDialogs()];
    })
  );

const reloadPageEpic = (action$, state$, { tracksManager, roomManager }) =>
  action$.pipe(
    filter(conferenceReload.match),
    mergeMap(() => {
      tracksManager.removeAllTracks();
      roomManager.destroy();
      window.location.reload();
      return [];
    })
  );

const changeNameEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(changeNameReq.match),
    mergeMap(({ payload: { userName } }) => {
      const room = roomManager.getRoom();
      const storageName = JSON.parse(localStorage.getItem("display-name"));
      const { jwt, decodedJwt } = state$.value.userReducer;
      const jwtDisplayName = decodedJwt?.context?.user?.displayName;

      const isJibri = state$.value.conferenceReducer.isJibri;

      if (isJibri) {
        const recordingSession =
          state$.value.recordingReducer.recordingSession ||
          JSON.parse(localStorage.getItem("latest-rec-session"));
        room.setDisplayName(recordingSession.id);
      }

      if (userName) {
        if (jwt === storageName?.jwt) {
          if (userName === jwtDisplayName) {
            room.setDisplayName(storageName.name);

            return [changeNameOk({ userName: storageName.name })];
          }

          if (userName !== jwtDisplayName) {
            localStorage.setItem(
              "display-name",
              JSON.stringify({ name: userName, jwt: jwt })
            );
            room.setDisplayName(userName);

            return [changeNameOk({ userName })];
          }
        }

        if (jwt !== storageName?.jwt) {
          room.setDisplayName(userName);

          localStorage.setItem(
            "display-name",
            JSON.stringify({ name: userName, jwt: jwt })
          );
          return [changeNameOk({ userName })];
        }

        return [];
      }

      if (storageName) {
        room.setDisplayName(storageName.name);

        return [changeNameOk({ userName: storageName.name })];
      }

      return [changeNameFail()];
    })
  );

const onDominantChangeEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(conferenceDominantSpeakerChanged.match),
    mergeMap(({ payload: { userId } }) => {
      const room = roomManager.getRoom();
      const pinnedUser = state$.value.conferenceReducer.pinnedUser;
      const myUserId = state$.value.conferenceReducer.userId;

      if (!pinnedUser && userId !== myUserId) {
        room.selectParticipant(userId);
      }
      return [];
    })
  );

const onUserPinEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(conferenceTogglePinUser.match),
    mergeMap(({ payload: { userId } }) => {
      const room = roomManager.getRoom();
      const myUserId = state$.value.conferenceReducer.userId;

      if (userId !== myUserId) {
        room.selectParticipant(userId);
      }
      return [];
    })
  );

/**
 * @description Initialize muteAudio listeners
 */
const muteParticipantListenerEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(() => {
      const room = roomManager.getRoom();

      const muteAudioObservable = getCommandsObserver(
        room,
        MUTE_PARTICIPANT_AUDIO
      ).pipe(
        mergeMap(([command, sender]) => {
          const myUserId = state$.value.conferenceReducer.userId;

          const { value: userId } = command;
          const localTracks = state$.value.tracksReducer.localTracks;

          if (userId === myUserId && !localTracks.audio.isMuted) {
            return [toggleTrackReq({ track: localTracks.audio })];
          }

          return [];
        })
      );

      const muteVideoObservable = getCommandsObserver(
        room,
        MUTE_PARTICIPANT_VIDEO
      ).pipe(
        mergeMap(([command, sender]) => {
          const myUserId = state$.value.conferenceReducer.userId;

          const { value: userId } = command;
          const localTracks = state$.value.tracksReducer.localTracks;

          if (userId === myUserId && !localTracks.video.isMuted) {
            return [toggleTrackReq({ track: localTracks.video })];
          }

          return [];
        })
      );

      return merge(muteAudioObservable, muteVideoObservable).pipe(
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

const muteParticipantEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(muteParticipantReq.match),
    mergeMap(({ payload: { type, userId } }) => {
      const room = roomManager.getRoom();

      const sendMuteCommand = (type, userId, from) =>
        new Promise((resolve) => {
          room.sendCommandOnce(
            type === "audio" ? MUTE_PARTICIPANT_AUDIO : MUTE_PARTICIPANT_VIDEO,
            {
              value: userId,
              attributes: {
                from,
              },
            }
          );

          resolve();
        });

      return from(sendMuteCommand(type, userId, room.myUserId())).pipe(
        mergeMap(() => [muteParticipantOk({ type, userId })]),
        catchError(() => [muteParticipantFail()])
      );
    })
  );

/**
 * @description Initialize muteAudio listeners
 */
const unmuteParticipantListenerEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(() => {
      const room = roomManager.getRoom();

      const unmuteAudioObservable = getCommandsObserver(
        room,
        REQUEST_UNMUTE_PARTICIPANT_AUDIO
      ).pipe(
        mergeMap(([command, sender]) => {
          const myUserId = state$.value.conferenceReducer.userId;

          const { value: userId } = command;
          const localTracks = state$.value.tracksReducer.localTracks;

          if (userId === myUserId && localTracks.audio.isMuted) {
            return [unmuteRequested({ type: "audio" })];
          }

          return [];
        })
      );

      const unmuteVideoObservable = getCommandsObserver(
        room,
        REQUEST_UNMUTE_PARTICIPANT_VIDEO
      ).pipe(
        mergeMap(([command, sender]) => {
          const myUserId = state$.value.conferenceReducer.userId;

          const { value: userId } = command;
          const localTracks = state$.value.tracksReducer.localTracks;

          if (userId === myUserId && localTracks.video.isMuted) {
            return [unmuteRequested({ type: "video" })];
          }

          return [];
        })
      );

      return merge(unmuteAudioObservable, unmuteVideoObservable).pipe(
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

const unmuteParticipantEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(unmuteParticipantReq.match),
    mergeMap(({ payload: { type, userId } }) => {
      const room = roomManager.getRoom();

      const sendMuteCommand = (type, userId, from) =>
        new Promise((resolve) => {
          room.sendCommandOnce(
            type === "audio"
              ? REQUEST_UNMUTE_PARTICIPANT_AUDIO
              : REQUEST_UNMUTE_PARTICIPANT_VIDEO,
            {
              value: userId,
              attributes: {
                from,
              },
            }
          );

          resolve();
        });

      return from(sendMuteCommand(type, userId, room.myUserId())).pipe(
        mergeMap(() => [unmuteParticipantOk({ type, userId })]),
        catchError(() => [unmuteParticipantFail()])
      );
    })
  );

/**
 * @description Initialize kick participant listeners
 */
const kickParticipantListenerEpic = (
  action$,
  _state$,
  { JitsiMeetJS, roomManager }
) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(() => {
      const room = roomManager.getRoom();

      const iHaveBeenKickedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.KICKED
      ).pipe(mergeMap(() => [conferenceDisconnect()]));

      const userHasBeenKickedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.PARTICIPANT_KICKED
      ).pipe(
        mergeMap((data) => {
          console.log("k", data);

          return [];
        })
      );

      return merge(iHaveBeenKickedObserver, userHasBeenKickedObserver).pipe(
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

const kickParticipantEpic = (action$, _state$, { roomManager }) =>
  action$.pipe(
    filter(kickParticipantReq.match),
    mergeMap(({ payload: { userId } }) => {
      const room = roomManager.getRoom();

      const kickPromise = new Promise((resolve) => {
        room.kickParticipant(userId);
        resolve();
      });

      return from(kickPromise).pipe(
        mergeMap(() => [kickParticipantOk({ userId })]),
        catchError(() => [kickParticipantFail()])
      );
    })
  );

/**
 * @description Check if needed a room closing after a user left
 */
const roomCheckModeratorsEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      return action$.pipe(
        filter(conferenceUserLeft.match),
        mergeMap(() => {
          const imModerator = state$.value.conferenceReducer.isModerator;
          const otherModerators = Object.values(
            state$.value.conferenceReducer.participantsDetails
          ).filter(
            (participant) =>
              participant?.role === "moderator" && !participant?.inLobby
          );

          let actions = [];
          if (!imModerator && !otherModerators.length) {
            actions.push(startCountDown());
          }

          return actions;
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Check if a moderator joined the room before it is closed
 */
const roomCheckModeratorJoinedEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      return action$.pipe(
        filter(conferenceParticipantPropertyChanged.match),
        mergeMap(({ payload: { userId, userDetails } }) => {
          if (
            state$.value.conferenceReducer.closing &&
            state$.value.conferenceReducer.participantsDetails[userId]?.role ===
              "moderator" &&
            !state$.value.conferenceReducer.participantsDetails[userId]?.inLobby
          ) {
            return [stopCountDown()];
          }

          return [];
        }),
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Room closing start countdown
 */
const roomStartClosingEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      return action$.pipe(
        filter(startCountDown.match),
        delay(60000),
        mergeMap(() => of(stopCountDown()))
      );
    }),
    takeUntil(action$.ofType(conferenceDisconnect.toString()))
  );

/**
 * @description Room closing stop countdown or room closing
 */
const roomStopClosingEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      return action$.pipe(
        filter(stopCountDown.match),
        mergeMap(() => {
          const imModerator = state$.value.conferenceReducer.isModerator;

          const otherModerators = Object.values(
            state$.value.conferenceReducer.participantsDetails
          ).filter(
            (participant) =>
              participant?.role === "moderator" && !participant?.inLobby
          );

          if (!imModerator && !otherModerators.length) {
            return [closeRoom()];
          }

          return [];
        })
      );
    }),
    takeUntil(action$.ofType(conferenceDisconnect.toString()))
  );

/**
 * @description Room close
 */
const roomCloseEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      return action$.pipe(
        filter(closeRoom.match),
        mergeMap(() => {
          return [conferenceDisconnect()];
        })
      );
    }),
    takeUntil(action$.ofType(conferenceDisconnect.toString()))
  );

export const conferenceEpic = combineEpics(
  initializeConnectionEpic,
  initializeRoomEpic,
  joinRoomEpic,
  roomFailEpic,
  connectionFailEpic,
  roomEventsEpic,
  enterRoomEpic,
  participantsEpic,
  disconnectionEpic,
  reloadPageEpic,
  lobbyLockEpic,
  joinRequestListenerEpic,
  joinRequestEpic,
  joinRequestAcceptEpic,
  joinRequestDenyEpic,
  roomTogglePasswordEpic,
  roomPasswordObserverEpic,
  changeNameEpic,
  onDominantChangeEpic,
  onUserPinEpic,
  muteParticipantEpic,
  muteParticipantListenerEpic,
  kickParticipantEpic,
  kickParticipantListenerEpic,
  unmuteParticipantEpic,
  unmuteParticipantListenerEpic,
  roomCheckModeratorJoinedEpic,
  roomCheckModeratorsEpic,
  roomStartClosingEpic,
  roomStopClosingEpic,
  roomCloseEpic
);
