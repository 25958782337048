import { combineEpics } from "redux-observable";
import { of, fromEventPattern } from "rxjs";
import { filter, mergeMap, take } from "rxjs/operators";
import {
  dropboxTokenLoad,
  dropboxLoginReq,
  dropboxTokenUpdate,
} from "./dropbox.slice";
import { enterRoomOk } from "../jitsi/conference.slice";
import { getEmitterEventObserver } from "../jitsi/jitsi.utils";

/**
 * @description Load dropbox token from storage if present
 */
const initializeDropboxEpic = (action$) =>
  action$.pipe(
    filter(enterRoomOk.match),
    mergeMap(() => {
      const tokenFromStorage = JSON.parse(localStorage.getItem("dropboxToken"));

      if (tokenFromStorage && tokenFromStorage.access_token) {
        return of(dropboxTokenLoad({ dropboxToken: tokenFromStorage }));
      }

      return [];
    })
  );

// export function* dropboxLoginSaga() {
//   let dropboxAPI = new Dropbox({ clientId: dropboxAppKey });

//   const url = dropboxAPI.getAuthenticationUrl(
//     `${window.location.protocol}//${window.location.host}`
//   );

//   const win = window.open(url);

//   const storageChannel = yield eventChannel((emit) => {
//     const storageListenerCallback = (e) => {
//       if (e.type === "storage" && e.storageArea?.dropboxToken) {
//         emit({
//           event: "dropboxTokenUpdate",
//           dropboxToken: e.storageArea?.dropboxToken,
//         });
//       }
//     };
//     window.addEventListener("storage", storageListenerCallback);

//     return () => {
//       window.removeEventListener("storage", storageListenerCallback);
//     };
//   }, buffers.expanding(5));

//   try {
//     let [eventChannel, delayed] = yield race([
//       take(storageChannel),
//       delay(45000, true),
//     ]);

//     if (eventChannel) {
//       const { dropboxToken } = eventChannel;

//       const parsedToken = JSON.parse(dropboxToken);

//       if (parsedToken?.error && parsedToken.error === "access_denied") {
//         toast.error(i18n.t("notification_dropbox_auth_missing"));
//       } else {
//         yield put(dropboxTokenUpdate(parsedToken));
//       }

//       storageChannel.close();
//     }

//     if (delayed) {
//       toast.error(i18n.t("notification_dropbox_auth_timeout"));
//     }

//     win.close();
//   } catch (error) {
//     console.log("dropbox error", error);
//   }
// }

/**
 * @description Login to dropbox
 */
const dropboxLoginEpic = (action$, _, { dropboxAPI }) =>
  action$.pipe(
    filter(dropboxLoginReq.match),
    mergeMap(() => {
      const url = dropboxAPI.getAuthenticationUrl(
        `${window.location.protocol}//${window.location.host}`
      );

      const win = window.open(url);

      const storageObserver = fromEventPattern(
        (handler) => window.addEventListener("storage", handler),
        (handler) => {
          window.removeEventListener("storage", handler);
          win.close();
        }
      ).pipe(
        mergeMap((e) => {
          console.log("storage", e);
          if (!e.type === "storage" || !e.storageArea?.dropboxToken) {
            return [];
          }

          const dropboxToken = JSON.parse(e.storageArea?.dropboxToken);

          if (dropboxToken?.error && dropboxToken.error === "access_denied") {
            return [];
          }

          return [dropboxTokenUpdate({ dropboxToken })];
        }),
        take(1)
      );

      return storageObserver;
    })
  );

export const dropboxEpic = combineEpics(
  initializeDropboxEpic,
  dropboxLoginEpic
);
