import { combineEpics } from "redux-observable";
import JitsiMeetJS from "@lyno/lib-jitsi-meet";
import { from, of, merge, fromEventPattern } from "rxjs";
import {
  catchError,
  filter,
  mergeMap,
  map,
  takeUntil,
  delay,
  exhaustMap,
  concatMap,
} from "rxjs/operators";
import {
  convertTrack,
  getEmitterEventObserver,
  remoteLog,
} from "./jitsi.utils";
import {
  permissionsPromptIsShown,
  createLocalTracksReq,
  createLocalTracksOk,
  toggleTrackReq,
  toggleTrackOk,
  addTrackEventListeners,
  removeTrackEventListeners,
  remoteTrackAdded,
  remoteTrackRemoved,
  remoteTrackMuteChanged,
  toggleScreenSharingReq,
  toggleScreenSharingOk,
  muteCamToShareScreen,
  unMuteToShareScreen,
  deviceListChanged,
  deviceListFail,
  deviceChangeReq,
  deviceChangeOk,
  deviceChangeFail,
  setPresenterEffect,
  setPresenterEffectFail,
  resetUnmuteRequest,
  toggleDeviceOnScreenSharing,
  permissionsError,
  permissionsGranted,
  // remoteTrackAudioLevelChanged,
} from "./tracks.slice";
import {
  conferenceDisconnect,
  conferenceJoined,
  conferenceUpdateParticipantTrack,
  connectionEstablished,
  initializeRoomOk,
} from "./conference.slice";
import { jitsiInitOk, jitsiGenericError } from "./user.slice";
import {
  createPresenterEffect,
  createMixedAudioEffect,
} from "../../api/user/screenSharing";
import { isFirefox, isSafari } from "react-device-detect";

const applyEffect = async (type, desktopTrack, videoTrack) => {
  // Need to wait because the stream is not immediately available
  await new Promise((resolve) => setTimeout(resolve, 100));

  let effect;
  if (type === "none") {
    effect = undefined;
  } else {
    effect = createPresenterEffect(videoTrack.stream);
  }

  return desktopTrack.setEffect(effect);
};

const manageTrack = async (room, oldTrack, track) => {
  if (!track && oldTrack) {
    await room.removeTrack(oldTrack);
  }

  if (track && oldTrack) {
    await room.replaceTrack(oldTrack, track);
  }

  if (track && !oldTrack) {
    await room.addTrack(track);
  }
};

const deviceListReq = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    let devices = await navigator.mediaDevices.enumerateDevices();

    const tracks = stream.getTracks();
    if (tracks) {
      for (let t = 0; t < tracks.length; t++) tracks[t].stop();
    }
    return devices;
  } catch (error) {
    return error.name;
  }
};

const mixTracks = (trackOne, trackTwo) => {
  let mixer = JitsiMeetJS.createAudioMixer();
  mixer.addMediaStream(trackOne.stream);
  mixer.addMediaStream(trackTwo.stream);
  const mixedTrack = mixer.start();
  return mixedTrack;
};

/**
 * @description Listen to PERMISSION_PROMPT_IS_SHOWN event from Jitsi Lib and
 * dispatch the new list of devices
 */
const permissionsPromptEpic = (action$, _state$, { JitsiMeetJS }) =>
  action$.pipe(
    filter(jitsiInitOk.match),
    mergeMap(() =>
      getEmitterEventObserver(
        JitsiMeetJS.mediaDevices,
        JitsiMeetJS.events.mediaDevices.PERMISSION_PROMPT_IS_SHOWN
      ).pipe(
        mergeMap((isShown) => of(permissionsPromptIsShown({ isShown }))),
        catchError((error) => of(jitsiGenericError({ error })))
      )
    )
  );

/**
 * @description Creates local tracks and permissions
 */
const localTracksEpic = (action$, _state$, { JitsiMeetJS, tracksManager }) =>
  action$.pipe(
    filter(createLocalTracksReq.match),
    mergeMap(({ payload: { micDeviceId, cameraDeviceId } }) => {
      return from(
        JitsiMeetJS.createLocalTracks(
          {
            devices: ["audio", "video"],
            micDeviceId,
            cameraDeviceId,
            constraints: {
              noiseSuppression: true,
              volume: 1,
              video: {
                aspectRatio: 4 / 3,
                height: {
                  ideal: 720,
                },
              },
            },
          },
          false
        )
      ).pipe(
        mergeMap((localTracks) => {
          const tracks = localTracks.map((track) => {
            tracksManager.addTrack(track);

            return convertTrack(track);
          });
          return of(createLocalTracksOk({ tracks }));
        }),
        catchError((error) => console.log(error))
      );
    })
  );

const trackToggleEpic = (action$, state$, { tracksManager }) =>
  action$.pipe(
    filter(toggleTrackReq.match),
    mergeMap(({ payload: { track: trackToMute } }) => {
      const track = tracksManager.getTrack(trackToMute.id);
      const isMuted = track.isMuted();
      const isScreenSharing = state$.value.tracksReducer.isScreenSharing;

      let actions = [];
      let trackToggled;

      if (isMuted) {
        trackToggled = true;

        if (track.type === state$.value.tracksReducer.unmuteRequested) {
          actions.push(resetUnmuteRequest());
        }

        if (isScreenSharing && trackToMute.type === "video") {
          trackToggled = false;
          actions.push(setPresenterEffectFail());
        }
      } else {
        trackToggled = true;

        if (isScreenSharing && trackToMute.type === "video") {
          trackToggled = false;
          actions.push(setPresenterEffectFail());
        }
      }

      if (trackToggled) {
        if (isMuted) {
          track.unmute();
        }
        if (!isMuted) {
          track.mute();
        }
        actions.unshift(
          toggleTrackOk({ track: trackToMute, isMuted: !isMuted })
        );
      }
      return actions;
    })
  );

const addTrackListenersEpic = (action$, _state$, { tracksManager }) =>
  action$.pipe(
    filter(addTrackEventListeners.match),
    mergeMap(({ payload: { track: trackInfo } }) => {
      const track = tracksManager.getTrack(trackInfo.id);

      return [];
    })
  );

const removeTrackListenersEpic = (action$, _state$, { tracksManager }) =>
  action$.pipe(
    filter(removeTrackEventListeners.match),
    mergeMap(({ payload: { track: trackInfo } }) => {
      const track = tracksManager.getTrack(trackInfo.id);

      return [];
    })
  );

const roomTracksEpic = (
  action$,
  state$,
  { roomManager, JitsiMeetJS, tracksManager }
) =>
  action$.pipe(
    filter(conferenceJoined.match),
    mergeMap(({ payload: { roomName } }) => {
      const room = roomManager.getRoom();

      const isJibri = room._statsCurrentId === "jibri";

      const remoteTrackAddedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.TRACK_ADDED
      ).pipe(
        delay(1500),
        filter(
          (track) =>
            isJibri ||
            state$.value.tracksReducer.localTracks?.[track.type]?.id !==
              track?.track?.id
        ),
        map((track) => {
          tracksManager.addTrack(track);
          return convertTrack(track);
        }),
        mergeMap((remoteTrack) =>
          of(
            remoteTrackAdded({ remoteTrack }),
            conferenceUpdateParticipantTrack({
              participantId: remoteTrack.owner,
              track: remoteTrack,
            })
          )
        )
      );

      const remoteTrackRemovedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.TRACK_REMOVED
      ).pipe(
        map((track) => {
          const convertedTrack = convertTrack(track);

          if (!convertedTrack.isLocal) {
            tracksManager.removeTrack(track.track.id);
          }

          return convertedTrack;
        }),
        mergeMap((remoteTrack) =>
          of(
            remoteTrackRemoved({ track: remoteTrack }),
            conferenceUpdateParticipantTrack({
              participantId: remoteTrack.owner,
              track: remoteTrack,
            })
          )
        )
      );

      const remoteTrackMuteChangedObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED
      ).pipe(
        mergeMap((track) => {
          const t = track.length ? track[0] : track;

          return of(
            remoteTrackMuteChanged({
              track: convertTrack(t),
            })
          );
        })
      );

      // TOO EXPENSIVE
      // const remoteTrackAudioLevelChanged = getEmitterEventObserver(
      //   room,
      //   JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED
      // ).pipe(mergeMap((track) => of(remoteTrackAudioLevelChanged({track}))));

      return merge(
        remoteTrackAddedObserver,
        remoteTrackRemovedObserver,
        remoteTrackMuteChangedObserver
        // remoteTrackAudioLevelChanged
      ).pipe(takeUntil(action$.ofType(conferenceDisconnect.toString())));
    })
  );

/**
 * @description Toggle screensharing
 */
const screenSharingEpic = (
  action$,
  state$,
  { JitsiMeetJS, tracksManager, roomManager }
) =>
  action$.pipe(
    filter(toggleScreenSharingReq.match),
    exhaustMap(() => {
      const isScreenSharing = state$.value.tracksReducer.isScreenSharing;

      if (isScreenSharing) {
        const room = roomManager.getRoom();

        const micDeviceId =
          state$.value.tracksReducer.selectedDevices.audioinput;
        const cameraDeviceId =
          state$.value.tracksReducer.selectedDevices.videoinput;

        const oldDesktopDescriptor =
          state$.value.tracksReducer.localTracks.desktop;
        const oldDesktopTrack = tracksManager.getTrack(oldDesktopDescriptor.id);
        const oldDesktopAudioDescriptor =
          state$.value.tracksReducer.localTracks.desktopAudio;

        return from(manageTrack(room, oldDesktopTrack, null)).pipe(
          mergeMap(() => {
            oldDesktopAudioDescriptor &&
              tracksManager.removeTrack(oldDesktopAudioDescriptor.id);
            tracksManager.removeTrack(oldDesktopDescriptor.id);

            return from(
              JitsiMeetJS.createLocalTracks(
                {
                  devices: ["audio", "video"],
                  micDeviceId,
                  cameraDeviceId,
                  constraints: {
                    noiseSuppression: true,
                    volume: 1,
                    video: {
                      aspectRatio: 4 / 3,
                      height: {
                        ideal: 720,
                      },
                    },
                  },
                },
                false
              )
            ).pipe(
              mergeMap((localTracks) => {
                let actions = [];

                const tracks = localTracks.map((track) => {
                  const oldTrackDescriptor =
                    state$.value.tracksReducer.localTracks[track.type];
                  const oldTrack = tracksManager.getTrack(
                    oldTrackDescriptor.id
                  );

                  if (oldTrackDescriptor.isMuted) {
                    actions.push(
                      toggleTrackReq({ track: convertTrack(track) })
                    );
                  }

                  tracksManager.addTrack(track);
                  from(manageTrack(room, oldTrack, track)).pipe(
                    mergeMap(() => {
                      tracksManager.removeTrack(oldTrackDescriptor.id);
                    })
                  );

                  return convertTrack(track);
                });

                actions.unshift(createLocalTracksOk({ tracks: tracks }));
                actions.unshift(
                  toggleScreenSharingOk({ track: null, audioTrack: null })
                );

                return actions;
              }),
              catchError((error) => {
                console.log(error);
                return [];
              })
            );
          })
        );
      }

      return from(
        JitsiMeetJS.createLocalTracks(
          {
            devices: ["desktop"],
            volume: 0.5,
          },
          false
        )
      ).pipe(
        mergeMap((localTracks) => {
          let actions = [];

          const camActivated =
            !state$.value.tracksReducer.localTracks.video.isMuted;

          if ((isSafari || isFirefox) && camActivated) {
            localTracks.map((localTrack) => {
              localTrack.dispose();
            });
            return [muteCamToShareScreen()];
          }

          const audioTrack = localTracks.filter(
            (track) => track.type === "audio"
          )[0];

          if (audioTrack) {
            const localAudioTrackTranscriptor =
              state$.value.tracksReducer.localTracks.audio;
            const localAudioTrack = tracksManager.getTrack(
              localAudioTrackTranscriptor.id
            );

            if (localAudioTrackTranscriptor.isMuted) {
              localTracks.map((localTrack) => {
                localTrack.dispose();
              });
              return [unMuteToShareScreen()];
            }
            tracksManager.addTrack(audioTrack);

            const mixedStream = mixTracks(audioTrack, localAudioTrack);
            localAudioTrack.setEffect(createMixedAudioEffect(mixedStream));
          }

          const videoTrack = localTracks.filter(
            (track) => track.type === "video"
          )[0];

          const oldTrackDescriptor =
            state$.value.tracksReducer.localTracks.video;
          const oldTrack = tracksManager.getTrack(oldTrackDescriptor.id);

          const room = roomManager.getRoom();

          return from(manageTrack(room, oldTrack, videoTrack)).pipe(
            mergeMap(() => {
              tracksManager.addTrack(videoTrack);

              actions.push(
                toggleScreenSharingOk({
                  track: convertTrack(videoTrack),
                  audioTrack: audioTrack && convertTrack(audioTrack),
                })
              );

              if (oldTrack && !oldTrackDescriptor.isMuted) {
                actions.push(setPresenterEffect({ effect: "default" }));
              }
              if (oldTrack && oldTrackDescriptor.isMuted) {
                actions.push(setPresenterEffect({ effect: "none" }));
              }

              return actions;
            })
          );
        }),
        catchError((error) => {
          console.log(error);
          return [];
        })
      );
    })
  );

/**
 * @description Toggle screensharing on browser button click
 */
const screenSharingOnEndedEpic = (
  action$,
  state$,
  { JitsiMeetJS, tracksManager, roomManager }
) =>
  action$.pipe(
    filter(toggleScreenSharingOk.match),
    mergeMap(({ payload: { track } }) => {
      if (track) {
        const newTrack = tracksManager.getTrack(track.id);

        return fromEventPattern(
          (handler) => (newTrack.track.onended = handler),
          (handler, subscription) => {}
        ).pipe(
          mergeMap(() => {
            return of(toggleScreenSharingReq());
          })
        );
      }

      return [];
    })
  );

/**
 * @description Presenter effect epic
 */
const presenterEffectEpic = (action$, state$, { tracksManager, roomManager }) =>
  action$.pipe(
    filter(setPresenterEffect.match),
    mergeMap(({ payload: { effect } }) => {
      const localVideoTrackDescriptor =
        state$.value.tracksReducer.localTracks.video;

      const localVideoTrack = tracksManager.getTrack(
        localVideoTrackDescriptor.id
      );

      const desktopTrackDescriptor =
        state$.value.tracksReducer.localTracks.desktop;

      const desktopTrack =
        desktopTrackDescriptor &&
        tracksManager.getTrack(desktopTrackDescriptor.id);

      applyEffect(effect, desktopTrack, localVideoTrack);
      return [];
    })
  );

/**
 * @description Change input device
 */
const deviceChangeEpic = (
  action$,
  state$,
  { JitsiMeetJS, tracksManager, roomManager }
) =>
  action$.pipe(
    filter(deviceChangeReq.match),
    mergeMap(({ payload: { deviceType, deviceId } }) => {
      const isScreenSharing = state$.value.tracksReducer.isScreenSharing;

      if (isScreenSharing) {
        return of(toggleDeviceOnScreenSharing());
      }

      const type =
        deviceType === "audioinput" || deviceType === "audiooutput"
          ? "audio"
          : "video";

      const options = {
        devices: [type],
        micDeviceId: deviceType === "audioinput" ? deviceId : null,
        cameraDeviceId: deviceType === "videoinput" ? deviceId : null,
      };

      return from(JitsiMeetJS.createLocalTracks(options, false)).pipe(
        mergeMap((localTracks) => {
          const track = localTracks?.[0];

          // Get the current track
          const oldTrackId = state$.value.tracksReducer.localTracks[type].id;
          const oldTrack = tracksManager.getTrack(oldTrackId);

          // Get the room object
          const room = roomManager.getRoom();

          const inLobby = state$.value.conferenceReducer.inLobby;

          if (inLobby) {
            tracksManager.removeTrack(oldTrackId);
            tracksManager.addTrack(track);
            return of(
              deviceChangeOk({
                deviceType,
                deviceId,
              })
            );
          }

          // Replace the track
          return from(manageTrack(room, oldTrack, track)).pipe(
            mergeMap(() => {
              // Update tracks inside the track manager
              tracksManager.removeTrack(oldTrackId);
              tracksManager.addTrack(track);

              return of(
                deviceChangeOk({
                  deviceType,
                  deviceId,
                })
              );
            })
          );
        }),
        catchError((error) => console.log(error))
      );
    })
  );

/**
 * @description Detect when user joins and create device list after permissions are granted from the user
 */
const deviceListEpic = (action$, state$, { JitsiMeetJS }) =>
  action$.pipe(
    filter(initializeRoomOk.match),
    mergeMap(() => {
      const isHidden = state$.value.userReducer.isHidden;
      if (isHidden) {
        return [];
      }
      return from(deviceListReq()).pipe(
        mergeMap((devices) => {
          if (devices === "NotAllowedError") {
            return [conferenceDisconnect(), permissionsError()];
          }

          return of(deviceListChanged({ devices }), permissionsGranted());
        })
      );
    }),
    catchError((error) => {
      console.log(error);
      return of(deviceListFail());
    })
  );

export const tracksEpic = combineEpics(
  permissionsPromptEpic,
  localTracksEpic,
  trackToggleEpic,
  addTrackListenersEpic,
  removeTrackListenersEpic,
  roomTracksEpic,
  screenSharingEpic,
  deviceListEpic,
  deviceChangeEpic,
  presenterEffectEpic,
  screenSharingOnEndedEpic
);
