// @flow

const SET_INTERVAL = 1;
const CLEAR_INTERVAL = 2;
const INTERVAL_TIMEOUT = 3;

const code = `
    var timer;

    onmessage = function(request) {
        switch (request.data.id) {
        case ${SET_INTERVAL}: {
            timer = setInterval(() => {
                postMessage({ id: ${INTERVAL_TIMEOUT} });
            }, request.data.timeMs);
            break;
        }
        case ${CLEAR_INTERVAL}: {
            if (timer) {
                clearInterval(timer);
            }
            break;
        }
        }
    };
`;

const timerWorkerScript = URL.createObjectURL(
  new Blob([code], { type: "application/javascript" })
);

/**
 * Represents a modified MediaStream that adds video as pip on a desktop stream.
 * <tt>JitsiStreamPresenterEffect</tt> does the processing of the original
 * desktop stream.
 */
export default class JitsiStreamPresenterEffect {
  _canvas;
  _ctx;
  _desktopElement;
  _desktopStream;
  _frameRate;
  _onVideoFrameTimer;
  _onVideoFrameTimerWorker;
  _renderVideo;
  _videoFrameTimerWorker;
  _videoElement;
  isEnabled;
  startEffect;
  stopEffect;

  /**
   * Represents a modified MediaStream that adds a camera track at the
   * bottom right corner of the desktop track using a HTML canvas.
   * <tt>JitsiStreamPresenterEffect</tt> does the processing of the original
   * video stream.
   *
   * @param {MediaStream} videoStream - The video stream which is user for
   * creating the canvas.
   */
  constructor(videoStream) {
    const videoDiv = document.createElement("div");
    const videoTracks = videoStream.getVideoTracks();
    const firstVideoTrack = videoTracks && videoTracks[0];

    const { width, height, frameRate } =
      firstVideoTrack.getSettings() ?? firstVideoTrack.getConstraints();

    this._canvas = document.createElement("canvas");
    this._ctx = this._canvas.getContext("2d");

    this._desktopElement = document.createElement("video");
    this._videoElement = document.createElement("video");
    videoDiv.appendChild(this._videoElement);
    videoDiv.appendChild(this._desktopElement);
    if (document.body !== null) {
      document.body.appendChild(videoDiv);
    }

    // Set the video element properties
    this._frameRate = parseInt(frameRate, 10);
    this._videoElement.width = parseInt(width, 10);
    this._videoElement.height = parseInt(height, 10);
    this._videoElement.autoplay = true;
    this._videoElement.srcObject = videoStream;

    // set the style attribute of the div to make it invisible
    videoDiv.style.display = "none";

    // Bind event handler so it is only bound once for every instance.
    this._onVideoFrameTimer = this._onVideoFrameTimer.bind(this);
  }

  /**
   * EventHandler onmessage for the videoFrameTimerWorker WebWorker.
   *
   * @private
   * @param {EventHandler} response - The onmessage EventHandler parameter.
   * @returns {void}
   */
  _onVideoFrameTimer(response) {
    if (response.data.id === INTERVAL_TIMEOUT) {
      this._renderVideo();
    }
  }

  /**
   * Loop function to render the video frame input and draw presenter effect.
   *
   * @private
   * @returns {void}
   */
  _renderVideo() {
    // adjust the canvas width/height on every frame incase the window has been resized.
    const track = this._desktopStream?.getVideoTracks()[0];
    const { height, width } = track.getSettings() ?? track.getConstraints();

    const parsedWidth = parseInt(width, 10);
    const computedWidth =
      parsedWidth === 0 ? window.screen.availWidth : parsedWidth;

    const parsedHeight = parseInt(height, 10);
    const computedHeight =
      parsedHeight === 0 ? window.screen.availHeight : parsedHeight;

    const quarterWidth = this._videoElement.width / 4;
    const quarterHeight = this._videoElement.height / 4;

    this._canvas.width = computedWidth;
    this._canvas.height = computedHeight - quarterHeight;

    this._ctx.drawImage(
      this._desktopElement,
      quarterWidth,
      0,
      this._canvas.width - quarterWidth,
      this._canvas.height
    );
    this._ctx.drawImage(this._videoElement, 0, 0, quarterWidth, quarterHeight);

    // draw a border around the video element.
    // this._ctx.beginPath();
    // this._ctx.strokeStyle = "#2a363b";
    // this._ctx.rect(0, 0, quarterWidth, quarterHeight);
    // this._ctx.lineWidth = 4;
    // this._ctx.stroke();
  }

  /**
   * Checks if the local track supports this effect.
   *
   * @param {JitsiLocalTrack} jitsiLocalTrack - Track to apply effect.
   * @returns {boolean} - Returns true if this effect can run on the
   * specified track, false otherwise.
   */
  isEnabled(jitsiLocalTrack) {
    return (
      jitsiLocalTrack.isVideoTrack() && jitsiLocalTrack.videoType === "desktop"
    );
  }

  /**
   * Starts loop to capture video frame and render presenter effect.
   *
   * @param {MediaStream} desktopStream - Stream to be used for processing.
   * @returns {MediaStream} - The stream with the applied effect.
   */
  startEffect(desktopStream) {
    // set the desktop element properties.
    this._desktopStream = desktopStream;
    this._desktopElement.autoplay = true;
    this._desktopElement.srcObject = desktopStream;

    this._videoFrameTimerWorker = new Worker(timerWorkerScript, {
      name: "Presenter effect worker",
    });
    this._videoFrameTimerWorker.onmessage = this._onVideoFrameTimer;
    this._videoFrameTimerWorker.postMessage({
      id: SET_INTERVAL,
      timeMs: 1000 / this._frameRate,
    });

    return this._canvas.captureStream(this._frameRate);
  }

  /**
   * Stops the capture and render loop.
   *
   * @returns {void}
   */
  stopEffect() {
    this._videoFrameTimerWorker.postMessage({
      id: CLEAR_INTERVAL,
    });
    this._videoFrameTimerWorker.terminate();
  }
}

export const createPresenterEffect = (stream) => {
  return new JitsiStreamPresenterEffect(stream);
};

export class JitsiStreamMixedAudioEffect {
  _mixedStream;
  isEnabled;
  startEffect;
  stopEffect;

  /**
   *
   * @param {MediaStream} mixedStream - The audio stream mixed from screensharing
   */
  constructor(mixedStream) {
    this._mixedStream = mixedStream;
  }

  /**
   * Checks if the local track supports this effect.
   *
   * @param {JitsiLocalTrack} jitsiLocalTrack - Track to apply effect.
   * @returns {boolean} - Returns true if this effect can run on the
   * specified track, false otherwise.
   */
  isEnabled(jitsiLocalTrack) {
    return jitsiLocalTrack.isAudioTrack();
  }

  /**
   *
   * @returns {MediaStream} - The stream with the applied effect.
   */
  startEffect() {
    return this._mixedStream;
  }

  /**
   * Stops the capture and render loop.
   *
   * @returns {void}
   */
  stopEffect() {}
}

export const createMixedAudioEffect = (mixedAudioStream) => {
  return new JitsiStreamMixedAudioEffect(mixedAudioStream);
};
