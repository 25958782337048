import { combineEpics } from "redux-observable";
import { of, merge } from "rxjs";
import {
  filter,
  concatMap,
  mergeMap,
  map,
  takeUntil,
  delay,
  throttleTime,
} from "rxjs/operators";
import { getCommandsObserver } from "./jitsi.utils";
import {
  emoteFireReq,
  emoteFireOk,
  emoteSet,
  emoteUnset,
  handRaiseToggle,
} from "./commands.slice";
import { EMOTE_FIRED, HAND_RAISED } from "./commands.constants";
import { conferenceDisconnect, enterRoomOk } from "./conference.slice";

const EMOTE_TIME = 10000;

/**
 * @description Initialize command listeners
 */
const initializeEmoteEpic = (action$, state$, { JitsiMeetJS, roomManager }) =>
  action$.pipe(
    filter(enterRoomOk.match),
    mergeMap(() => {
      const room = roomManager.getRoom();

      const emoteObservable = getCommandsObserver(room, EMOTE_FIRED).pipe(
        mergeMap(([data, userId]) => [emoteSet({ emote: data.value, userId })])
      );

      const handRaiseObservable = getCommandsObserver(room, HAND_RAISED).pipe(
        mergeMap(([data, userId]) => [
          handRaiseToggle({ emote: data.value, userId }),
        ])
      );

      return merge(emoteObservable, handRaiseObservable).pipe(
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Send an emote
 */
const emoteEpic = (action$, state$, { JitsiMeetJS, roomManager }) =>
  action$.pipe(
    filter(emoteFireReq.match),
    throttleTime(EMOTE_TIME),
    concatMap(({ payload: { emote } }) => {
      const room = roomManager.getRoom();

      room.sendCommandOnce(
        emote === "HAND_RAISE_EMOTE" ? HAND_RAISED : EMOTE_FIRED,
        {
          value: emote,
          attributes: {
            from: room.myUserId(),
          },
        }
      );

      return of(emoteFireOk({ emote }));
    })
  );

/**
 * @description Send an emote
 */
const emoteStopEpic = (action$, _state$, _) =>
  action$.pipe(
    filter(emoteSet.match),
    delay(EMOTE_TIME),
    map(({ payload: { emote, userId } }) => emoteUnset({ emote, userId }))
  );

export const commandsEpic = combineEpics(
  initializeEmoteEpic,
  emoteEpic,
  emoteStopEpic
);
