import { createSlice } from "@reduxjs/toolkit";
import { VIDEO_QUALITY_LEVELS } from "../../imports/constants";
import { decodeJWT } from "../../api/user/auth";

const initialState = {
  jwt: null,
  isMuted: {},
  preferredVideoQuality: VIDEO_QUALITY_LEVELS.HIGH,
  videoQuality: {
    audioOnly: false,
    preferredVideoQuality: VIDEO_QUALITY_LEVELS.HIGH,
  },
  decodedJwt: null,
  isHidden: false,
  displayName: null,
};

const userSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    jitsiInitOk: (state, { payload: { jwt } }) => {
      state.decodedJwt = jwt && decodeJWT(jwt);
      state.jwt = state.decodedJwt ? jwt : false;
      state.displayName =
        (jwt && state.decodedJwt?.context?.user?.displayName) ||
        JSON.parse(localStorage.getItem("display-name"))?.name;
      state.isHidden = jwt
        ? state.decodedJwt?.context?.user?.isHidden !== undefined
        : false;
      return state;
    },
    jitsiGenericError: (state, { payload: { error } }) => {
      return state;
    },
    setPreferredVideoQualityReq: () => {},
    setPreferredVideoQualityOk: (
      state,
      { payload: { preferredVideoQuality } }
    ) => {
      state.preferredVideoQuality = preferredVideoQuality;
      return state;
    },
    setPreferredVideoQualityFail: () => {},
    resetUserData: (state) => {
      state = initialState;
      return state;
    },
  },
});

const { actions, reducer } = userSlice;

export const {
  jitsiInitOk,
  jitsiGenericError,
  setPreferredVideoQualityReq,
  setPreferredVideoQualityOk,
  setPreferredVideoQualityFail,
} = actions;

export default reducer;
