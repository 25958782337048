const env = process.env.REACT_APP_ENV;
const defaultEnv = "DEV";

console.log("RUNNING ON ENV:", env);

function getEnvConfig(property) {
  console.log('env', `REACT_APP_${property}_${env}`)
  if (!process.env[`REACT_APP_${property}_${env}`]) {
    console.warn(
      `Missing environmental variable 'REACT_APP_${property}_${env}'. Fallback to 'REACT_APP_${property}_${defaultEnv}'`
    );
  }

  return (
    process.env[`REACT_APP_${property}_${env}`]
  );
}

export const serverOptions = {
  hosts: {
    domain: getEnvConfig("DOMAIN"),
    muc: getEnvConfig("MUC"),
  },
  serviceUrl: getEnvConfig("SERVICE_URL"),
  clientNode: getEnvConfig("CLIENT_NODE"),
};

export const confOptions = {
  openBridgeChannel: true,
  p2p: {
    enabled: false,
  },
};

export const initOptions = {
  disableAudioLevels: true,
};

export const brand = {
  name:
    process.env["REACT_APP_BRAND"] || process.env["REACT_APP_BRAND_DEFAULT"],
  favicon: process.env["REACT_APP_FAVICON"],
  logo: process.env["REACT_APP_LOGO"],
};

export const dropboxAppKey = getEnvConfig("DROPBOX_APP_KEY");

//!Deprecated
export const redirectUri = getEnvConfig("DROPBOX_REDIRECT_URL");

export const googleAppKey = getEnvConfig("GOOGLE_APP_KEY");
export const googleClientId = getEnvConfig("GOOGLE_CLIENT_ID");

export const msalConfig = {
  auth: {
    clientId: getEnvConfig("MSAL_CLIENT_ID"),
  },
};

export const recordingOptions = {
  dropbox: Boolean(dropboxAppKey),
};

export const calendarOptions = {
  microsoft: Boolean(msalConfig.auth.clientId),
  google: Boolean(googleAppKey) && Boolean(googleClientId),
};