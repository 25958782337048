import { combineEpics } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import {
  jitsiInitOk,
  setPreferredVideoQualityFail,
  setPreferredVideoQualityOk,
  setPreferredVideoQualityReq,
} from "./user.slice";
import { parseURLQuery } from "../../imports/utils";
import { VIDEO_QUALITY_LEVELS } from "../../imports/constants";
import { toggleTrackReq } from "./tracks.slice";
import { setVideoQuality } from "./jitsi.utils";

/**
 * @description Initializes JitsiMeetJS library at app startup
 */
const jitsiInit = (_action$, _state$, { JitsiMeetJS }) => {
  JitsiMeetJS.init();

  const locationSearch = window.location.search;
  const queryParams = parseURLQuery(locationSearch);
  const jwt = queryParams.jwt || window.localStorage.getItem("jwt-token");

  return of(jitsiInitOk({ jwt }));
};

const preferredVideoQualityEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(setPreferredVideoQualityReq.match),
    mergeMap(({ payload: { preferredVideoQuality } }) => {
      const room = roomManager.getRoom();

      const videoTrack = state$.value.tracksReducer.localTracks?.video;

      if (
        preferredVideoQuality === VIDEO_QUALITY_LEVELS.NONE &&
        !videoTrack?.isMuted
      ) {
        return [
          toggleTrackReq({ track: videoTrack }),
          setPreferredVideoQualityOk({ preferredVideoQuality }),
        ];
      }

      if (preferredVideoQuality !== VIDEO_QUALITY_LEVELS.NONE) {
        return from(setVideoQuality(room, preferredVideoQuality)).pipe(
          mergeMap(() => [
            setPreferredVideoQualityOk({ preferredVideoQuality }),
          ])
        );
      }
    }),
    catchError(() => [setPreferredVideoQualityFail()])
  );

export const userEpic = combineEpics(jitsiInit, preferredVideoQualityEpic);
