import { ofType } from "redux-observable";
import { exhaustMap } from "rxjs/operators";

export const swUpdateEpic = (action$) =>
  action$.pipe(
    ofType("SW_UPDATE"),
    exhaustMap(({ reg }) => {
      const registrationWaiting = reg.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });
        registrationWaiting.addEventListener("statechange", (e) => {
          if (e.target.state === "activated") {
            window.location.reload(true);
          }
        });
      }
    })
  );
