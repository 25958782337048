import { lazy } from "react";
import { createBrowserHistory } from "history";

export const browserHistory = createBrowserHistory();

const Home = lazy(() => import("../pages/home/Home"));
const Conference = lazy(() => import("../pages/conference/Conference"));

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/conference/:id",
    component: Conference,
  },
];

export default routes;
