import { createSlice } from "@reduxjs/toolkit";
import JitsiMeetJS from "@lyno/lib-jitsi-meet";

const initialState = {
  userId: null,
  connected: false,
  isJoined: false,
  inLobby: true,
  isModerator: false,
  isJibri: false,
  conferenceName: "",
  conferenceStartingTime: null,
  participants: [],
  participantsDetails: {},
  dominantSpeaker: null,
  pinnedUser: null,
  isLocked: false,
  isLobbyLocked: false,
  usersRequestingJoin: [],
  roomJoinRequested: false,
  passwordLoader: false,
  passwordRequired: false,
  chat: {
    messages: [],
    unreadMessages: 0,
    sendingPrivateMessageTo: false,
  },
  localEmote: null,
  localDisplayName: "",
  joinRequestStatus: null,
  isRoomRecording: false,
  jwtRejected: false,
  closing: false,
  joinedAt: null,
};

// MISSING ACTIONS
const conferenceSlice = createSlice({
  name: "conference",
  initialState,
  reducers: {
    initializeRoomReq: (state, { payload: { roomName } }) => {},
    initializeRoomOk: (
      state,
      { payload: { roomName, userId, isModerator, isJibri } }
    ) => {
      state.conferenceName = roomName;
      state.userId = userId;
      state.isModerator = isModerator;
      state.isJibri = isJibri;
      return state;
    },
    initializeRoomFail: (state, { payload: { error } }) => {},
    joinRoomReq: (state) => {
      state.passwordLoader = true;
      return state;
    },
    joinRoomOk: (state) => {
      state.passwordLoader = false;
      return state;
    },
    joinRoomFail: (state, { payload: { error } }) => {
      state.passwordLoader = false;
      return state;
    },
    enterRoomReq: (state, { payload: { roomName } }) => {},
    enterRoomOk: (state, { payload: { isModerator } }) => {
      state.isModerator = isModerator || state.isModerator;
      state.joinedAt = new Date().getTime();
      state.dominantSpeaker =
        state.participants.length > 0 ? state.dominantSpeaker : state.userId;
      state.isJoined = true;
      state.inLobby = false;
      return state;
    },
    enterRoomFail: (state, { payload: { roomName, error } }) => {},
    connectionEstablished: (state, { roomName, connection }) => {
      state.connected = true;
      return state;
    },
    connectionFailed: (state, { payload: { error } }) => {
      if (error[0] === "connection.passwordRequired") {
        state.jwtRejected = true;
      }
      if (error[0] === "connection.droppedError") {
        window.location.reload();
      }
      state.connected = false;
      return state;
    },
    connectionDisconnected: (state, { payload: { roomName } }) => {
      state.connected = false;
      return state;
    },
    conferenceFailed: (state, { payload: { error } }) => {
      if (error === "conference.passwordRequired") {
        state.passwordRequired = true;
      }
      return state;
    },
    conferenceJoined: (state, { payload: { roomName } }) => {
      state.passwordRequired = false;
      state.passwordLoader = false;
      return state;
    },
    conferenceCreatedTimestamp: (state, { timestamp }) => {
      state.conferenceStartingTime = timestamp;
      return state;
    },
    conferenceSubjectChanged: (state, { payload: { subject } }) => {
      state.isLobbyLocked = subject === "lockedLobby";
      return state;
    },
    conferenceConnectionInterrupted: (state, { payload: { roomName } }) => {},
    conferenceConnectionRestored: (state, { payload: { roomName } }) => {},
    conferenceLockStateChanged: (state, { payload: { roomName } }) => {},
    conferenceUserJoined: (state, { payload: { userId, userDetails } }) => {
      state.participants.push(userId);
      state.participantsDetails[userId] = userDetails;
      return state;
    },
    conferenceUserLeft: (state, { payload: { userId } }) => {
      state.participants = state.participants.filter(
        (participantId) => participantId !== userId
      );
      state.participantsDetails[userId] = undefined;
      return state;
    },
    conferenceDisplayNameChanged: (
      state,
      { payload: { userId, displayName } }
    ) => {
      if (state.participantsDetails[userId]) {
        state.participantsDetails[userId].displayName = displayName;
      }
      return state;
    },
    conferenceUpdateParticipantTrack: (
      state,
      { payload: { participantId, track } }
    ) => {
      if (!state.participantsDetails[participantId]) return;
      state.participantsDetails[participantId].tracks[track.type] = track.id;
      return state;
    },
    conferenceKicked: (state, { payload: { userId } }) => {},
    conferenceDominantSpeakerChanged: (state, { payload: { userId } }) => {
      state.dominantSpeaker = userId;
      return state;
    },
    conferenceParticipantPropertyChanged: (
      state,
      { payload: { userId, propertyName, propertyValue } }
    ) => {
      if (state.participantsDetails[userId]) {
        state.participantsDetails[userId][propertyName] = propertyValue;
      }
      return state;
    },
    conferenceDisconnect: (state) => {
      state = initialState;
      return state;
    },
    conferenceReload: (state) => {
      state = initialState;
      return state;
    },
    conferenceTogglePinUser: (state, { payload: { userId } }) => {
      if (state.pinnedUser === userId) {
        state.pinnedUser = null;
      } else {
        state.pinnedUser = userId;
      }
      return state;
    },
    setLobbyLockedReq: (state, { payload: { lobbyLocked } }) => {},
    setLobbyLockedOk: (state, { payload: { lobbyLocked } }) => {
      // optimistic UI
      state.isLobbyLocked = lobbyLocked;
      return state;
    },
    setLobbyLockedFail: (state) => {},
    joinRequestReceived: (state, { payload: { userId } }) => {
      const hasRequest = state.usersRequestingJoin.some(
        (request) => request.id === userId
      );

      if (!hasRequest) {
        state.usersRequestingJoin.push({ id: userId });
      }
      return state;
    },
    joinRequestRemoved: (state, { payload: { userId } }) => {
      state.usersRequestingJoin = state.usersRequestingJoin.filter(
        (request) => request.id !== userId
      );
      return state;
    },
    joinRequestAccepted: (state) => {
      state.joinRequestStatus = "accepted";
      return state;
    },
    joinRequestDenied: (state) => {
      state.joinRequestStatus = "denied";
      return state;
    },
    sendJoinRequestReq: () => {},
    sendJoinRequestOk: (state) => {
      state.joinRequestStatus = "sent";
      return state;
    },
    sendJoinRequestFail: (state) => {
      state.joinRequestStatus = "fail";
      return state;
    },
    acceptJoinRequest: (state, { payload: { userId } }) => {},
    denyJoinRequest: (state, { payload: { userId } }) => {},
    toggleConferencePasswordReq: () => {},
    toggleConferencePassword: (state, { payload: { password } }) => {
      if (state.isModerator) {
        state.roomPassword = password;
      }
      state.isLocked = !!password;
      return state;
    },
    changeNameReq: () => {},
    changeNameOk: (state, { payload: { userName } }) => {
      state.localDisplayName = userName;
      return state;
    },
    changeNameFail: (state) => {
      return state;
    },
    kickParticipantReq: (state, { payload: { userId } }) => {},
    kickParticipantOk: (state, { payload: { userId } }) => {},
    kickParticipantFail: () => {},
    muteParticipantReq: (state, { payload: { type, userId } }) => {},
    muteParticipantOk: (state, { payload: { type, userId } }) => {},
    muteParticipantFail: () => {},
    unmuteParticipantReq: (state, { payload: { type, userId } }) => {},
    unmuteParticipantOk: (state, { payload: { type, userId } }) => {},
    unmuteParticipantFail: () => {},
    toggleRoomRecording: (state, { payload: { isRoomRecording, userId } }) => {
      if (userId && isRoomRecording) {
        state.participants.push(userId);
        state.participantsDetails[userId] = {
          userId: userId,
          isJibri: true,
        };
      }
      if (userId && !isRoomRecording) {
        state.participants = state.participants.filter(
          (participantId) => participantId !== userId
        );
        state.participantsDetails[userId] = undefined;
      }
      state.isRoomRecording = isRoomRecording;
      return state;
    },
    startCountDown: (state) => {
      state.closing = true;
      return state;
    },
    stopCountDown: (state) => {
      state.closing = false;
      return state;
    },
    closeRoom: () => {},
  },
});

const { actions, reducer } = conferenceSlice;

export const {
  initializeRoomReq,
  initializeRoomOk,
  initializeRoomFail,
  enterRoomReq,
  enterRoomOk,
  enterRoomFail,
  connectionEstablished,
  connectionFailed,
  connectionDisconnected,
  conferenceFailed,
  conferenceJoined,
  conferenceCreatedTimestamp,
  conferenceSubjectChanged,
  conferenceConnectionInterrupted,
  conferenceConnectionRestored,
  conferenceLockStateChanged,
  conferenceUserJoined,
  conferenceUserLeft,
  conferenceDisplayNameChanged,
  conferenceKicked,
  conferenceDominantSpeakerChanged,
  conferenceParticipantPropertyChanged,
  conferenceUpdateParticipantTrack,
  conferenceDisconnect,
  conferenceReload,
  conferenceTogglePinUser,
  setLobbyLockedReq,
  setLobbyLockedOk,
  setLobbyLockedFail,
  joinRequestReceived,
  joinRequestRemoved,
  sendJoinRequestReq,
  sendJoinRequestOk,
  sendJoinRequestFail,
  acceptJoinRequest,
  denyJoinRequest,
  joinRequestAccepted,
  joinRequestDenied,
  toggleConferencePasswordReq,
  toggleConferencePassword,
  joinRoomReq,
  joinRoomOk,
  joinRoomFail,
  changeNameReq,
  changeNameOk,
  changeNameFail,
  kickParticipantReq,
  kickParticipantOk,
  kickParticipantFail,
  muteParticipantReq,
  muteParticipantOk,
  muteParticipantFail,
  unmuteParticipantReq,
  unmuteParticipantOk,
  unmuteParticipantFail,
  toggleRoomRecording,
  startCountDown,
  stopCountDown,
  closeRoom,
} = actions;

export default reducer;
