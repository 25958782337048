import { createSlice } from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";

const initialState = {
  localTracks: {},
  remoteTracks: [],
  permissions: false,
  isScreenSharing: false,
  devices: [],
  selectedDevices: {
    audioinput: null,
    audiooutput: null,
    videoinput: null,
  },
  presenterEffect: "none",
  unmuteRequested: null,
};

const tracksSlice = createSlice({
  name: "tracks",
  initialState,
  reducers: {
    permissionsPromptIsShown: (state, { payload: { isShown } }) => {
      state.permissionsPrompt = isShown;
      return state;
    },
    createLocalTracksReq: (
      state,
      { payload: { micDeviceId, cameraDeviceId } }
    ) => {
      return state;
    },
    createLocalTracksOk: (state, { payload: { tracks: localTracks } }) => {
      state.localTracks = {
        audio:
          localTracks.find((localTrack) => localTrack.type === "audio") ||
          state.localTracks.audio,
        video:
          localTracks.find(
            (localTrack) =>
              localTrack.type === "video" && localTrack.videoType === "camera"
          ) || state.localTracks.video,
        desktop:
          localTracks.find(
            (localTrack) =>
              localTrack.type === "video" && localTrack.videoType === "desktop"
          ) || state.localTracks.desktop,
      };
      return state;
    },
    toggleTrackReq: (state, { track }) => {
      return state;
    },
    toggleTrackOk: (state, { payload: { track, isMuted } }) => {
      state.localTracks[track.type].isMuted = isMuted;

      if (!isMuted && track.type === state.unmuteRequested) {
        state.unmuteRequested = null;
      }
      return state;
    },
    toggleTrackFail: (state, { payload: { track, isMuted } }) => {
      return state;
    },
    addTrackEventListeners: (state, { payload: { track } }) => {
      return state;
    },
    removeTrackEventListeners: (state, { payload: { track } }) => {
      return state;
    },
    trackAudioLevelChanged: (state, { payload: { track } }) => {
      return state;
    },
    trackMuteChanged: (state, { payload: { track } }) => {
      return state;
    },
    localTrackStopped: (state, { payload: { track } }) => {
      return state;
    },
    trackAudioOutputChanged: (state, { payload: { track } }) => {
      return state;
    },
    trackViteotypeChanged: (state, { payload: { track, videoType } }) => {
      const updatedTracks = state.remoteTracks.map((remoteTrack) =>
        remoteTrack.id === track.id ? { ...track, videoType } : remoteTrack
      );
      state.remoteTracks = updatedTracks;
      return state;
    },
    remoteTrackAdded: (state, { payload: { remoteTrack } }) => {
      if (remoteTrack.isLocal) {
        if (remoteTrack.videoType === "desktop") {
          state.localTracks.desktop = remoteTrack;
        } else {
          state.localTracks[remoteTrack.type] = remoteTrack;
        }
      } else {
        state.remoteTracks.push(remoteTrack);
      }
      return state;
    },
    remoteTrackRemoved: (state, { payload: { remoteTrack } }) => {
      state.remoteTracks.filter((track) => track !== remoteTrack);

      return state;
    },
    remoteTrackMuteChanged: (state, { payload: { track } }) => {
      const newTracks = state.remoteTracks.map((t) =>
        t.id === track.id ? { ...track } : t
      );
      state.remoteTracks = newTracks;

      return state;
    },
    remoteTrackAudioLevelChanged: (state, { payload: { remoteTrack } }) => {
      return state;
    },
    toggleScreenSharingReq: () => {},
    toggleScreenSharingOk: (state, { payload: { track, audioTrack } }) => {
      state.isScreenSharing = track?.videoType === "desktop" || false;
      state.localTracks.desktop = track;
      state.localTracks.desktopAudio = audioTrack;
      return state;
    },
    muteCamToShareScreen: () => {},
    unMuteToShareScreen: () => {},
    deviceListChanged: (state, { payload: { devices } }) => {
      const grouped = groupBy(devices, (device) => device.kind);

      if (!state.selectedDevices.audioinput) {
        state.selectedDevices.audioinput =
          grouped.audioinput?.[0]?.deviceId ||
          grouped.audioinput?.[0]?.groupId ||
          null;
      }

      if (!state.selectedDevices.audiooutput) {
        grouped.audiooutput = grouped.audiooutput || [
          {
            deviceId: "default",
            groupId: "unknown",
            kind: "audiooutput",
            label: "Default",
          },
        ];
        state.selectedDevices.audiooutput =
          grouped.audiooutput?.[0]?.deviceId ||
          grouped.audiooutput?.[0]?.groupId ||
          grouped.audiooutput[0].deviceId;
      }

      if (!state.selectedDevices.videoinput) {
        state.selectedDevices.videoinput =
          grouped.videoinput?.[0]?.deviceId ||
          grouped.videoinput?.[0]?.groupId ||
          null;
      }

      state.devices = grouped;
      return state;
    },
    deviceListFail: (state, { error }) => {
      return state;
    },
    deviceSelectAudioInput: (state, { payload: { deviceId } }) => {
      state.selectedDevices.audioinput = deviceId;
      return state;
    },
    deviceSelectAudioOutput: (state, { payload: { deviceId } }) => {
      state.selectedDevices.audiooutput = deviceId;
      return state;
    },
    deviceSelectVideoInput: (state, { payload: { deviceId } }) => {
      state.selectedDevices.videoinput = deviceId;
      return state;
    },
    deviceChangeReq: (state, { payload: { deviceType, deviceId } }) => {
      return state;
    },
    deviceChangeOk: (state, { payload: { deviceType, deviceId } }) => {
      state.selectedDevices[deviceType] = deviceId;
      return state;
    },
    deviceChangeFail: (state, { payload: { deviceType, deviceId } }) => {
      return state;
    },
    setPresenterEffect: (state, { payload: { effect } }) => {
      state.presenterEffect = effect;
      return state;
    },
    setPresenterEffectFail: () => {},
    unmuteRequested: (state, { payload: { type } }) => {
      state.unmuteRequested = type;
      return state;
    },
    resetTracks: (state) => {
      state = initialState;
      return state;
    },
    resetUnmuteRequest: (state) => {
      state.unmuteRequested = null;
      return state;
    },
    toggleDeviceOnScreenSharing: () => {},
    permissionsGranted: (state) => {
      state.permissions = true;
      return state;
    },
    permissionsError: (state) => {
      state.permissions = false;
      return state;
    },
  },
});

const { actions, reducer } = tracksSlice;

export const {
  permissionsPromptIsShown,
  createLocalTracksReq,
  createLocalTracksOk,
  toggleTrackReq,
  toggleTrackOk,
  toggleTrackFail,
  addTrackEventListeners,
  removeTrackEventListeners,
  trackAudioLevelChanged,
  trackMuteChanged,
  localTrackStopped,
  trackAudioOutputChanged,
  trackViteotypeChanged,
  remoteTrackAdded,
  remoteTrackRemoved,
  remoteTrackMuteChanged,
  remoteTrackAudioLevelChanged,
  toggleScreenSharingReq,
  toggleScreenSharingOk,
  deviceListChanged,
  deviceListFail,
  deviceSelectAudioInput,
  deviceSelectAudioOutput,
  deviceSelectVideoInput,
  deviceChangeReq,
  deviceChangeOk,
  deviceChangeFail,
  setPresenterEffect,
  setPresenterEffectFail,
  unmuteRequested,
  resetUnmuteRequest,
  toggleDeviceOnScreenSharing,
  permissionsGranted,
  permissionsError,
  muteCamToShareScreen,
  unMuteToShareScreen,
} = actions;

export default reducer;
