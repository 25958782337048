import { combineEpics } from "redux-observable";
import { of, fromEventPattern, merge, from } from "rxjs";
import {
  filter,
  mergeMap,
  takeUntil,
  concatMap,
  map,
  catchError,
  exhaustMap,
} from "rxjs/operators";
import {
  gapiLoadFail,
  gapiLoadOk,
  gcalLoginFail,
  gcalLoginOk,
  gcalLoginReq,
  gcalLogoutFail,
  gcalLogoutOk,
  gcalLogoutReq,
  gcalAuthStateChanged,
  msalAuthStateChanged,
  msalCreateCalendarEventFail,
  msalCreateCalendarEventOk,
  msalCreateCalendarEventReq,
  msalGetCalendarEventsFail,
  msalGetCalendarEventsOk,
  msalGetCalendarEventsReq,
  msalListCalendarsFail,
  msalListCalendarsOk,
  msalListCalendarsReq,
  msalLoginFail,
  msalLoginOk,
  msalLoginReq,
  msalLogoutFail,
  msalLogoutOk,
  msalLogoutReq,
  gcalListCalendarsReq,
  gcalListCalendarsOk,
  gcalGetCalendarEventsReq,
  gcalCreateCalendarEventReq,
  gcalGetCalendarEventsOk,
  gcalGetCalendarEventsFail,
  gcalCreateCalendarEventOk,
  gcalCreateCalendarEventFail,
  gcalSetCookieBlock,
} from "./calendar.slice";
import {
  createGcalCalendarEvents,
  createMsalCalendarEvent,
  getGcalCalendarEvents,
  getMsalCalendarEvents,
  listGcalCalendars,
  listMsalCalendars,
  tokenRequest,
} from "./calendar.utils";

import { googleAppKey, googleClientId } from "../../config";

/**
 * @description Initialize
 */
const initializeMsalEpic = (_action$, _state$, { microsoftCal }) => {
  const accounts = microsoftCal.getAllAccounts();
  const isSignedIn = accounts.length > 0;
  const user = isSignedIn ? accounts[0] : null;

  let actions = [msalAuthStateChanged({ isSignedIn, user })];

  if (isSignedIn) {
    actions.push(msalListCalendarsReq({ user }));
  }

  return actions;
};

/**
 * @description Login
 */
const msalLoginEpic = (action$, _state$, { microsoftCal }) =>
  action$.pipe(
    filter(msalLoginReq.match),
    mergeMap(() =>
      from(microsoftCal.loginPopup(tokenRequest)).pipe(
        mergeMap(({ account }) => [
          msalLoginOk({ user: account }),
          msalListCalendarsReq({ user: account }),
        ]),
        catchError(() => [msalLoginFail()])
      )
    )
  );

/**
 * @description Logout
 */
const msalLogoutEpic = (action$, state$, { microsoftCal }) =>
  action$.pipe(
    filter(msalLogoutReq.match),
    mergeMap(() => {
      const msalUser = state$.value.calendarReducer.msalUser;

      return from(
        microsoftCal.logoutPopup({
          account: msalUser,
          mainWindowRedirectUri: window.location.href,
        })
      ).pipe(
        mergeMap(() => [msalLogoutOk()]),
        catchError(() => [msalLogoutFail()])
      );
    })
  );

/**
 * @description get calendar events
 */
const msalGetCalendarEventsEpic = (action$, state$, { microsoftCal }) =>
  action$.pipe(
    filter(msalGetCalendarEventsReq.match),
    mergeMap(() => {
      const msalUser = state$.value.calendarReducer.msalUser;
      const msalTarget = state$.value.calendarReducer.msalTarget;

      return from(
        getMsalCalendarEvents(microsoftCal, msalUser, msalTarget)
      ).pipe(
        mergeMap((events) => [
          msalGetCalendarEventsOk({ events: events.value }),
        ]),
        catchError((e) => [msalGetCalendarEventsFail({ error: e })])
      );
    })
  );

/**
 * @description create calendar event
 */
const msalCreateCalendarEventEpic = (action$, state$, { microsoftCal }) =>
  action$.pipe(
    filter(msalCreateCalendarEventReq.match),
    mergeMap(({ payload: { event } }) => {
      const msalUser = state$.value.calendarReducer.msalUser;
      const msalTarget = state$.value.calendarReducer.msalTarget;

      return from(
        createMsalCalendarEvent(microsoftCal, msalUser, msalTarget, event)
      ).pipe(
        mergeMap((outlookEvent) => [
          msalCreateCalendarEventOk({ event: outlookEvent }),
        ]),
        catchError((e) => [msalCreateCalendarEventFail({ error: e })])
      );
    })
  );

/**
 * @description list msal calendars
 */
const msalListCalendarsEpic = (action$, state$, { microsoftCal }) =>
  action$.pipe(
    filter(msalListCalendarsReq.match),
    mergeMap(({ payload: { user } }) =>
      from(listMsalCalendars(microsoftCal, user)).pipe(
        mergeMap((response) => {
          const calendars = response.value
            .filter((cal) => cal.canEdit)
            .map((cal) => ({ label: cal.name, id: cal.id }));
          return [
            msalListCalendarsOk({
              calendars,
            }),
          ];
        }),
        catchError((e) => [msalListCalendarsFail({ error: e })])
      )
    )
  );

/**
 * @description Initialize Google API
 */
const initializeGapiEpic = (_action$, _state$, { gapi }) => {
  return from(
    new Promise((resolve) => gapi.load("client:auth2", resolve))
  ).pipe(
    exhaustMap(() => [gapiLoadOk()]),
    catchError((e) => of(gapiLoadFail({ error: e })))
  );
};

/**
 * @description Initialize GCal
 */
const initializeGCalEpic = (action$, _state$, { gapi }) =>
  action$.pipe(
    filter(gapiLoadOk.match),
    mergeMap(() => {
      const DISCOVERY =
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
      const SCOPE = "https://www.googleapis.com/auth/calendar";

      const client = window.gapi.client;

      return from(
        client.init({
          apiKey: googleAppKey,
          clientId: googleClientId,
          discoveryDocs: [DISCOVERY],
          scope: SCOPE,
        })
      ).pipe(
        mergeMap(() => {
          const GoogleAuth = window.gapi.auth2.getAuthInstance();
          const user = GoogleAuth?.currentUser.get();
          const isAuthorized = Boolean(user?.hasGrantedScopes(SCOPE));

          const authStateChangeListener = fromEventPattern(
            (handler) => GoogleAuth.isSignedIn.listen(handler),
            (handler, subscription) => {}
          ).pipe(
            mergeMap((data) => {
              console.log("auth state change", data);
              return [];
            })
          );

          let actions = [
            gcalAuthStateChanged({
              gcalIsSignedIn: Boolean(user.mc),
              user,
              isAuthorized,
            }),
          ];

          if (isAuthorized) {
            actions.push(gcalListCalendarsReq());
          }

          return merge(actions, authStateChangeListener).pipe();
        }),
        catchError((e) => of(gcalSetCookieBlock({ cookieBlock: true })))
      );
    })
  );

/**
 * @description Google Login
 */
const gcalLoginEpic = (action$, _state$, { gapi }) =>
  action$.pipe(
    filter(gcalLoginReq.match),
    mergeMap(() => {
      const GoogleAuth = gapi.auth2.getAuthInstance();

      console.log(GoogleAuth);

      return from(GoogleAuth.signIn()).pipe(
        mergeMap((user) => [gcalLoginOk({ user }), gcalListCalendarsReq()]),
        catchError((e) => [gcalLoginFail({ error: e })])
      );
    })
  );

/**
 * @description Google Logout
 */
const gcalLogoutEpic = (action$, _state$, { gapi }) =>
  action$.pipe(
    filter(gcalLogoutReq.match),
    mergeMap(() => {
      const GoogleAuth = gapi.auth2.getAuthInstance();

      return from(GoogleAuth.signOut()).pipe(
        mergeMap((user) => {
          return [gcalLogoutOk()];
        }),
        catchError((e) => [gcalLogoutFail({ error: e })])
      );
    })
  );

/**
 * @description list gcal calendars
 */
const gaclListCalendarsEpic = (action$, state$, { gapi }) =>
  action$.pipe(
    filter(gcalListCalendarsReq.match),
    mergeMap(() =>
      from(listGcalCalendars(gapi)).pipe(
        mergeMap((response) => {
          const calendars = response.items
            .filter((c) => c.accessRole !== "reader")
            .map((c) => ({ label: c.summary, id: c.id }));
          return [gcalListCalendarsOk({ calendars })];
        }),
        catchError((e) => [msalListCalendarsFail({ error: e })])
      )
    )
  );

/**
 * @description get calendar events from google
 */
const gcalGetCalendarEventsEpic = (action$, state$, { gapi }) =>
  action$.pipe(
    filter(gcalGetCalendarEventsReq.match),
    mergeMap(() => {
      // const gcalUser = state$.value.calendarReducer.gcalUser;
      const gcalTarget = state$.value.calendarReducer.gcalTarget;

      return from(getGcalCalendarEvents(gapi, gcalTarget)).pipe(
        mergeMap((events) => [
          gcalGetCalendarEventsOk({ events: events.items }),
        ]),
        catchError((e) => [gcalGetCalendarEventsFail({ error: e })])
      );
    })
  );

/**
 * @description create calendar event
 */
const gcalCreateCalendarEventEpic = (action$, state$, { gapi }) =>
  action$.pipe(
    filter(gcalCreateCalendarEventReq.match),
    mergeMap(({ payload: { event } }) => {
      const gcalTarget = state$.value.calendarReducer.gcalTarget;

      return from(createGcalCalendarEvents(gapi, gcalTarget, event)).pipe(
        mergeMap((googleEvent) => [
          gcalCreateCalendarEventOk({ event: googleEvent }),
        ]),
        catchError((e) => [gcalCreateCalendarEventFail({ error: e })])
      );
    })
  );

export const calendarEpic = combineEpics(
  initializeMsalEpic,
  initializeGapiEpic,
  initializeGCalEpic,
  msalLoginEpic,
  msalLogoutEpic,
  msalGetCalendarEventsEpic,
  msalCreateCalendarEventEpic,
  msalListCalendarsEpic,
  gcalLoginEpic,
  gcalLogoutEpic,
  gaclListCalendarsEpic,
  gcalGetCalendarEventsEpic,
  gcalCreateCalendarEventEpic
);
