import { callMSGraph, postMSGraph } from "../../api/user/calendars";

export const tokenRequest = {
  scopes: ["user.read", "Calendars.ReadWrite"],
};

export const getMsalCalendarEvents = async (microsoftCal, user, target) => {
  const res = await microsoftCal.acquireTokenSilent({
    ...tokenRequest,
    account: user,
  });

  const todayDate = new Date();
  const parsedDate = todayDate.toISOString().slice(0, -8);
  const filter = `?$filter=start/dateTime ge '${parsedDate}'`;

  if (target === "default") {
    return callMSGraph("/me/calendar/events" + filter, res.accessToken);
  } else {
    return callMSGraph(
      "/me/calendars/" + target + "/events" + filter,
      res.accessToken
    );
  }
};

export const createMsalCalendarEvent = async (
  microsoftCal,
  user,
  target,
  event
) => {
  const res = await microsoftCal.acquireTokenSilent({
    ...tokenRequest,
    account: user,
  });

  if (target === "default") {
    return postMSGraph("/me/calendar/events", res.accessToken, event);
  } else {
    return postMSGraph(
      "/me/calendars/" + target + "/events",
      res.accessToken,
      event
    );
  }
};

export const listMsalCalendars = async (microsoftCal, user) => {
  const token = await microsoftCal.acquireTokenSilent({
    ...tokenRequest,
    account: user,
  });

  return callMSGraph("/me/calendars", token.accessToken);

  // .then((response) => {
  //   setOutlookUserCalendars(
  // response.value
  //   .filter((c) => c.canEdit)
  //   .map((c) => ({ label: c.name, id: c.id }))
  //   );
  // });
};

export const listGcalCalendars = async (gapi) => {
  const request = gapi.client.calendar.calendarList.list();

  return new Promise((resolve) => request.execute(resolve));
};

export const getGcalCalendarEvents = (gapi, gcalTarget) => {
  const request = gapi.client.calendar.events.list({
    calendarId: gcalTarget,
    timeMin: new Date().toISOString(),
  });

  return new Promise((resolve) => request.execute(resolve));
};

export const createGcalCalendarEvents = (gapi, gcalTarget, event) => {
  const request = window.gapi.client.calendar.events.insert({
    calendarId: gcalTarget,
    resource: event,
  });

  return new Promise((resolve) => request.execute(resolve));
};
