import { fromEventPattern } from "rxjs";

export const getEmitterEventObserver = (emitter, eventName) =>
  fromEventPattern(
    (handler) => emitter.addEventListener(eventName, handler),
    (handler) => emitter.removeEventListener(eventName, handler)
  );

export const getCommandsObserver = (room, commandName) =>
  fromEventPattern(
    (handler) => room.addCommandListener(commandName, handler),
    (handler) => room.removeCommandListener(commandName, handler)
  );

export const parsePropertyValue = (propertyValue) => {
  if (propertyValue === "true") {
    return true;
  } else if (propertyValue === "false") {
    return false;
  } else if (isNaN(propertyValue)) {
    return propertyValue;
  } else {
    return parseFloat(propertyValue);
  }
};

export const convertTrack = (track) => ({
  type: track.type,
  id: track.track.id,
  deviceId: track.deviceId,
  isMuted: track.isMuted(),
  owner: track.getParticipantId() || track.ownerEndpointId,
  videoType: track.videoType,
  isLocal: track.isLocal(),
});

export const setVideoQuality = async (room, preferredVideoQuality) => {
  await room.setReceiverVideoConstraint(preferredVideoQuality);
  return room.setSenderVideoConstraint(preferredVideoQuality);
};

export const remoteLog = (url, data) => {
  fetch("https://iltumio.dev", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data,
    }),
  })
    .then(() => console.log("logged"))
    .catch((e) => console.log("log error", e));
};
