import { combineEpics } from "redux-observable";
import { of, fromEventPattern, merge, from } from "rxjs";
import {
  filter,
  mergeMap,
  takeUntil,
  concatMap,
  mapTo,
  map,
} from "rxjs/operators";
import {
  increaseUnreadMessages,
  messageReceived,
  privateMessageReceived,
  resetUnreadMessages,
  sendChatMessageFailHidden,
  sendChatMessageOk,
  sendChatMessageReq,
  setPrivateMessageRecipient,
} from "./chat.slice";
import { conferenceDisconnect, enterRoomOk } from "../jitsi/conference.slice";
import { getEmitterEventObserver } from "../jitsi/jitsi.utils";
import { parseMessage } from "./chat.utils";
import { uiSetChatVisibility } from "../ui/ui.slice";

/**
 * @description Add chat listeners
 */
const initializeChatEpic = (action$, state$, { JitsiMeetJS, roomManager }) =>
  action$.pipe(
    filter(enterRoomOk.match),
    mergeMap(() => {
      const room = roomManager.getRoom();

      const publicMessagesObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.MESSAGE_RECEIVED
      ).pipe(
        mergeMap((messageData) => {
          if (messageData[1] === "The owner is gone") {
            return [];
          }

          const message = parseMessage(messageData);

          const userId = state$.value.conferenceReducer.userId;
          const isLocal = message.from === userId;
          const chatVisibility = state$.value.uiReducer.chatVisibility;
          const isHidden = state$.value.userReducer.isHidden;
          const isSenderModerator =
            state$.value.conferenceReducer.participantsDetails[message.from]
              ?.role === "moderator";

          if (isHidden && message.recipient === "all" && !isSenderModerator) {
            return [];
          }

          let actions = [
            messageReceived({
              message,
            }),
          ];

          if (!isLocal && !chatVisibility) {
            actions.push(increaseUnreadMessages());
          }

          return actions;
        })
      );

      const privateMessagesObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED
      ).pipe(
        mergeMap((messageData) => {
          const message = parseMessage(messageData);

          const userId = state$.value.conferenceReducer.userId;
          const isLocal = message.from === userId;
          const participantsDetails =
            state$.value.conferenceReducer.participantsDetails;

          const displayNameFrom = isLocal
            ? state$.value.conferenceReducer.localDisplayName || userId
            : participantsDetails[message.from]?.displayName;

          const displayNameTo = isLocal
            ? participantsDetails[message.from]?.displayName
            : state$.value.conferenceReducer.localDisplayName || userId;

          const chatVisibility = state$.value.uiReducer.chatVisibility;

          let actions = [
            privateMessageReceived({
              message,
              sender: displayNameFrom,
              recipient: displayNameTo,
              to: message.recipient,
            }),
          ];

          if (!isLocal && !chatVisibility) {
            actions.push(increaseUnreadMessages());
          }

          return actions;
        })
      );

      return merge(publicMessagesObserver, privateMessagesObserver).pipe(
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Handles the message sending
 */
const sendChatMessageEpic = (action$, state$, { JitsiMeetJS, roomManager }) =>
  action$.pipe(
    filter(sendChatMessageReq.match),
    concatMap(({ payload: { message, type, to } }) => {
      const isHidden = state$.value.userReducer.isHidden;

      if (isHidden && !to) {
        return [sendChatMessageFailHidden()];
      }

      const room = roomManager.getRoom();

      const msgString = JSON.stringify({
        msg: message,
        msgType: type,
        to: to || "all",
      });

      const userId = state$.value.conferenceReducer.userId;
      const sender = state$.value.conferenceReducer.localDisplayName;
      const recipient =
        state$.value.conferenceReducer?.participantsDetails[to]?.displayName ||
        to;

      room.sendMessage(msgString, to);

      return [
        sendChatMessageOk({ userId, sender, recipient, message, type, to }),
      ];
    })
  );

/**
 * @description Reset unread
 */
const resetUnreadEpic = (action$, state$, { JitsiMeetJS, roomManager }) =>
  action$.pipe(
    filter(
      ({ type, payload }) =>
        type === uiSetChatVisibility.toString() && payload.chatVisibility
    ),
    map(() => resetUnreadMessages())
  );

/**
 * @description Handles the message sending
 */
const setPrivateMessageRecipientEpic = (
  action$,
  state$,
  { JitsiMeetJS, roomManager }
) =>
  action$.pipe(
    filter(setPrivateMessageRecipient.match),
    concatMap(({ payload: { to } }) => {
      if (to) {
        return [uiSetChatVisibility({ chatVisibility: true })];
      }

      return [];
    })
  );

export const chatEpic = combineEpics(
  initializeChatEpic,
  sendChatMessageEpic,
  resetUnreadEpic,
  setPrivateMessageRecipientEpic
);
