// CUSTOM COMMANDS

// Emote
export const HAND_RAISED = "HAND_RAISED";
export const EMOTE_FIRED = "EMOTE_FIRED";

// Join Request
export const JOIN_REQUEST = "JOIN_REQUEST";
export const JOIN_REQUEST_ACCEPTED = "JOIN_REQUEST_ACCEPTED";
export const JOIN_REQUEST_DENIED = "JOIN_REQUEST_DENIED";

// Subroom
export const SUBROOM_INVITATION = "SUBROOM_INVITATION";

// Mute Participant
export const MUTE_PARTICIPANT_AUDIO = "MUTE_PARTICIPANT_AUDIO";
export const MUTE_PARTICIPANT_VIDEO = "MUTE_PARTICIPANT_VIDEO";
export const REQUEST_UNMUTE_PARTICIPANT_AUDIO =
  "REQUEST_UNMUTE_PARTICIPANT_AUDIO";
export const REQUEST_UNMUTE_PARTICIPANT_VIDEO =
  "REQUEST_UNMUTE_PARTICIPANT_VIDEO";

// Recording
export const RECORDING_CHANGED = "RECORDING_CHANGED";

// Room Lock Password
export const ROOM_CLOSE = "ROOM_CLOSE";
export const ROOM_OPEN = "ROOM_OPEN";
