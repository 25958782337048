export const parseMessage = ([from, message, time, sender]) => {
  const parsedPayload = JSON.parse(message);
  return {
    from,
    message: parsedPayload.msg,
    type: parsedPayload.msgType,
    time: time || Date.now(),
    sender,
    recipient: parsedPayload.to,
  };
};
