export const VERSION = "2.1.14";

export const VIDEO_QUALITY_LEVELS = {
  HIGH: 720,
  STANDARD: 360,
  LOW: 180,
  NONE: 0,
};
export const allowedImageExt = [
  "JPG",
  "JPEG",
  "JPE",
  "JIF",
  "JFIF",
  "JFI",
  "PNG",
  "GIF",
  "WEBP",
  "TIFF",
  "TIF",
  "PSD",
  "RAW",
  "ARW",
  "CR2",
  "NRW",
  "K25",
  "BMP",
  "DIB",
  "HEIF",
  "HEIC",
  "IND",
  "INDD",
  "INDT",
  "JP2",
  "J2K",
  "JPF",
  "JPX",
  "JPM",
  "MJ2",
  "SVG",
  "SVGZ",
];
export const allowedDocumentExt = [
  "DOC",
  "DOCX",
  "HTML",
  "HTM",
  "ODT",
  "PDF",
  "XLS",
  "XLSX",
  "ODS",
  "PPT",
  "PPTX",
  "TXT",
  "PDF",
  "AI",
  "EPS",
];
export const allowedVideoOrAudioExt = [
  "WEBM",
  "MPG",
  "MP2",
  "MP3",
  "MPEG",
  "MPE",
  "MPV",
  "OGG",
  "MP4",
  "M4P",
  "M4V",
  "AVI",
  "WMV",
  "MOV",
  "QT",
  "FLV",
  "SWF",
  "AVCHD",
];
