import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatVisibility: false,
  settingDialogVisibility: false,
  subroomDialogVisibility: false,
  subroomInvitedParticipants: [],
  participantsListVisibility: false,
  downloadChatDialogVisibility: false,
  dialog: { show: false, props: null },
  modal: { show: false, props: null },
  layoutMode: "lateral",
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    uiSetChatVisibility: (state, { payload: { chatVisibility } }) => {
      state.chatVisibility = chatVisibility;
      return state;
    },
    uiSetSettingDialogVisibility: (
      state,
      { payload: { settingDialogVisibility } }
    ) => {
      state.settingDialogVisibility = settingDialogVisibility;
      return state;
    },
    uiSetParticipantsListVisibility: (
      state,
      { payload: { participantsListVisibility } }
    ) => {
      state.participantsListVisibility = participantsListVisibility;

      return state;
    },
    uiSetSubroomDialogVisibility: (
      state,
      { payload: { subroomDialogVisibility, invitedParticipants } }
    ) => {
      state.subroomDialogVisibility = subroomDialogVisibility;
      state.subroomInvitedParticipants =
        subroomDialogVisibility && invitedParticipants.length
          ? invitedParticipants
          : [];
      return state;
    },
    uiSetDisconnectModalVisibility: (
      state,
      { payload: { disconnectModalVisibility } }
    ) => {
      state.disconnectModalVisibility = disconnectModalVisibility;

      return state;
    },
    uiSetDownloadChatDialogVisibility: (
      state,
      { payload: { downloadChatDialogVisibility } }
    ) => {
      state.downloadChatDialogVisibility = downloadChatDialogVisibility;

      return state;
    },
    uiToggleModal: (state, { payload: props }) => {
      const isShown = state.modal.show;
      state.modal.show = !isShown;
      state.modal.props = isShown ? null : props;
      return state;
    },
    uiOpenModal: (state, { payload: props }) => {
      state.modal.show = true;
      state.modal.props = props;
      return state;
    },
    uiCloseModal: (state) => {
      state.modal.show = false;
      state.modal.props = null;
      return state;
    },
    uiToggleDialog: (state, { payload: props }) => {
      const isShown = state.dialog.show;
      state.dialog.show = !isShown;
      state.dialog.props = isShown ? null : props;
      return state;
    },
    uiOpenDialog: (state, { payload: props }) => {
      state.dialog.show = true;
      state.dialog.props = props;
      return state;
    },
    uiCloseDialog: (state) => {
      state.dialog.show = false;
      state.dialog.props = null;
      return state;
    },
    uiResetDialogs: (state) => {
      state = initialState;
      return state;
    },
    setLayoutMode: (state, { payload: { layoutMode } }) => {
      state.layoutMode = layoutMode;
      return state;
    },
  },
});

const { actions, reducer } = uiSlice;

export const {
  uiSetChatVisibility,
  uiSetSettingDialogVisibility,
  uiSetParticipantsListVisibility,
  uiSetDisconnectModalVisibility,
  uiToggleModal,
  uiToggleDialog,
  uiCloseModal,
  uiCloseDialog,
  uiOpenModal,
  uiOpenDialog,
  uiResetDialogs,
  setLayoutMode,
  uiSetSubroomDialogVisibility,
  uiSetDownloadChatDialogVisibility,
} = actions;

export default reducer;
