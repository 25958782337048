// Helper function to call Microsoft Graph API endpoint
// using authorization bearer token scheme
export function callMSGraph(endpoint, token) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  //console.log("request made to Graph API at: " + new Date().toString());

  return fetch("https://graph.microsoft.com/v1.0" + endpoint, options).then(
    (response) => response.json()
  );
}

export async function postMSGraph(endpoint, token, body) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const jsonBody = JSON.stringify(body);

  const options = {
    method: "POST",
    headers: headers,
    body: jsonBody,
  };

  //console.log("post request made to Graph API at: " + new Date().toString());

  const response = await fetch(
    "https://graph.microsoft.com/v1.0" + endpoint,
    options
  );

  return response.json();
}
