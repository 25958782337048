import jwtDecode from "jwt-decode";

export function decodeJWT(token) {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function getJibriAuthCredentials() {
  const usernameOverride = window.localStorage.getItem(
    "xmpp_username_override"
  );
  const passwordOverride = window.localStorage.getItem(
    "xmpp_password_override"
  );

  if (usernameOverride && passwordOverride) {
    return { username: usernameOverride, password: passwordOverride };
  } else {
    return false;
  }
}
