import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import JitsiMeetJS from "@lyno/lib-jitsi-meet";
import { rootEpic } from "./redux/epics.index";
import { rootReducer } from "./redux/reducer.index";
import { RoomManager } from "./redux/jitsi/RoomManager";
import { TracksManager } from "./redux/jitsi/TracksManager";
import { dropboxAppKey } from "./config";
import { Dropbox } from "dropbox";
import * as Msal from "@azure/msal-browser";
import { toast } from "react-toastify";

import { browserHistory } from "./router/config";
import { msalConfig } from "./config";
import i18n from "./i18n";

export const roomManager = new RoomManager();
export const tracksManager = new TracksManager();

const dropboxAPI = new Dropbox({ clientId: dropboxAppKey });

const microsoftCal = new Msal.PublicClientApplication(msalConfig);

const gapi = window.gapi;

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    JitsiMeetJS,
    roomManager,
    tracksManager,
    browserHistory,
    dropboxAPI,
    microsoftCal,
    gapi,
    toast,
    i18n,
  },
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(epicMiddleware))
);

epicMiddleware.run(rootEpic);

export default store;
