import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  localEmote: null,
  participantsEmotes: {},
  emoteError: null,
};

// MISSING ACTIONS
const commandsSlice = createSlice({
  name: "commands",
  initialState,
  reducers: {
    emoteFireReq: (state, { payload: { emote } }) => {
      state.emoteError = null;
      state.localEmote = null;
      return state;
    },
    emoteFireOk: (state, { payload: { emote } }) => {
      state.localEmote = emote;
      return state;
    },
    emoteFireFail: (state, { payload: { emote, error } }) => {
      state.emoteError = error;
      return state;
    },
    emoteSet: (state, { payload: { userId, emote } }) => {
      state.participantsEmotes[userId] = emote;
      return state;
    },
    emoteUnset: (state, { payload: { userId, emote } }) => {
      delete state.participantsEmotes[userId];
      return state;
    },
    handRaiseToggle: (state, { payload: { userId, emote } }) => {
      const currentEmote = state.participantsEmotes[userId];

      if (currentEmote === "HAND_RAISE_EMOTE") {
        delete state.participantsEmotes[userId];
      } else {
        state.participantsEmotes[userId] = emote;
      }
      return state;
    },
  },
});

const { actions, reducer } = commandsSlice;

export const {
  emoteFireReq,
  emoteFireOk,
  emoteFireFail,
  emoteSet,
  emoteUnset,
  handRaiseToggle,
} = actions;

export default reducer;
