import { combineEpics } from "redux-observable";
import { catchError } from "rxjs/operators";
import { conferenceEpic } from "./jitsi/conference.epic";
import { tracksEpic } from "./jitsi/tracks.epic";
import { userEpic } from "./jitsi/user.epic";
import { commandsEpic } from "./jitsi/commands.epic";
import { dropboxEpic } from "./dropbox/dropbox.epic";
import { recordingEpic } from "./jitsi/recording.epic";
import { chatEpic } from "./chat/chat.epic";
import { calendarEpic } from "./calendar/calendar.epic";
import { notificationEpic } from "./jitsi/notifications.epic";
import { swUpdateEpic } from "./serviceWorker/serviceWorker.epic";

const epics = [
  swUpdateEpic,
  userEpic,
  conferenceEpic,
  tracksEpic,
  commandsEpic,
  dropboxEpic,
  recordingEpic,
  chatEpic,
  calendarEpic,
  notificationEpic,
];

export const rootEpic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );
