export class RoomManager {
  constructor() {
    this.connection = null;
    this.roomName = null;
    this.room = null;
  }

  isInitialized() {
    return Boolean(this.connection && this.roomName && this.room);
  }

  async initialize(connection, roomName, roomOptions) {
    this.room = connection.initJitsiConference(roomName, roomOptions);

    this.addJibriRequirements(this.room);

    return;
  }

  addJibriRequirements(room) {
    window.room = room;
    //Making room data needed for Jibri recordings available
    window.APP = {
      conference: {
        _room: room,

        getStats() {
          return room.connectionQuality.getStats();
        },
      },
    };
    Object.defineProperty(window.APP.conference, "membersCount", {
      get: () => {
        return room.getParticipants().length + 1;
      },
    });
  }

  removeJibriRequirements() {
    delete window.APP;
  }

  destroy() {
    if (this.room) {
      try {
        this.room.leave();
      } catch (e) {
        console.log("Failed to leave the room");
      }
      // TODO check how to properly destroy the room
      // this.room.destroy();
      this.room = null;
      this.connection = null;
      this.roomName = null;
      this.removeJibriRequirements();
    }
  }

  getRoom() {
    return this.room;
  }
}
