import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recordingStatus: null,
  recordingSession: null,
  recordingError: null,
};

const recordingSlice = createSlice({
  name: "recording",
  initialState,
  reducers: {
    startRecordingReq: (state, { payload: { type, dropboxToken } }) => {
      state.recordingError = null;
      return state;
    },
    startRecordingOk: (state) => {
      return state;
    },
    startRecordingFail: (state, { payload: { error } }) => {
      state.recordingSession = null;
      state.recordingStatus = null;
      state.recordingError = error;
      return state;
    },
    stopRecordingReq: () => {},
    stopRecordingOk: (state) => {
      state = initialState;
      return state;
    },
    stopRecordingFail: (state, { payload: { error } }) => {
      state.recordingError = error;
      return state;
    },
    recordingStateChanged: (state, { payload: { recordingSession } }) => {
      state.recordingSession = recordingSession;
      state.recordingStatus = recordingSession?.status;
      return state;
    },
    resetRecording: (state) => {
      state = initialState;
      return state;
    },
  },
});

const { actions, reducer } = recordingSlice;

export const {
  startRecordingReq,
  startRecordingOk,
  startRecordingFail,
  recordingStateChanged,
  resetRecording,
  stopRecordingReq,
  stopRecordingOk,
  stopRecordingFail,
} = actions;

export default reducer;
