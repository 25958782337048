export class TracksManager {
  constructor() {
    this.tracks = {};
  }

  addTrack(track) {
    const id = track.track.id;
    this.tracks[id] = track;
    this.tracks[track.track.id] = track;
  }

  getTrack(id) {
    return this.tracks[id];
  }

  removeTrack(id) {
    const track = this.tracks[id];

    if (!track) return;

    track.dispose();
    delete this.tracks[id];
  }

  removeAllTracks() {
    Object.keys(this.tracks).forEach((id) => {
      this.removeTrack(id);
    });
  }
}
