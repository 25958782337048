import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  msalIsSignedIn: null,
  msalUser: null,
  msalLoading: false,
  msalCalendarEvents: [],
  msalTarget: "default",
  msalCalendars: [],
  gapiLoaded: false,
  gcalLoading: false,
  gcalIsSignedIn: null,
  gcalUser: null,
  gcalCalendars: [],
  gcalCalendarEvents: [],
  gcalTarget: "default",
  gcalCookieBlock: false,
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    msalAuthStateChanged: (state, { payload: { isSignedIn, user } }) => {
      state.msalIsSignedIn = isSignedIn;
      state.msalUser = user;
      return state;
    },
    msalLoginReq: (state) => {
      state.msalLoading = true;
      return state;
    },
    msalLoginOk: (state, { payload: { user } }) => {
      state.msalLoading = false;
      state.msalIsSignedIn = true;
      state.msalUser = user;
      return state;
    },
    msalLoginFail: (state) => {
      state.msalLoading = false;
      return state;
    },
    msalLogoutReq: (state) => {
      state.msalLoading = true;
      return state;
    },
    msalLogoutOk: (state) => {
      state = initialState;
      return state;
    },
    msalLogoutFail: (state) => {
      state.msalLoading = false;
      return state;
    },
    msalGetCalendarEventsReq: (state) => {
      state.msalLoading = true;
      return state;
    },
    msalGetCalendarEventsOk: (state, { payload: { events } }) => {
      state.msalLoading = false;
      if (events?.length) {
        state.msalCalendarEvents = events;
      }
      return state;
    },
    msalGetCalendarEventsFail: (state) => {
      state.msalLoading = false;
      return state;
    },
    msalCreateCalendarEventReq: (state) => {
      state.msalLoading = true;
      return state;
    },
    msalCreateCalendarEventOk: (state, { payload: { event } }) => {
      state.msalLoading = false;
      state.msalCalendarEvents.push(event);
      return state;
    },
    msalCreateCalendarEventFail: (state) => {
      state.msalLoading = false;
      return state;
    },
    msalListCalendarsReq: (state, { payload: { user } }) => {
      state.msalLoading = true;
      return state;
    },
    msalListCalendarsOk: (state, { payload: { calendars } }) => {
      state.msalLoading = false;
      state.msalCalendars = calendars;
      return state;
    },
    msalListCalendarsFail: (state) => {
      state.msalLoading = false;
      return state;
    },
    msalSetTargetCalendar: (state, { payload: { calendarId } }) => {
      state.msalTarget = calendarId;
      return state;
    },
    gapiLoadOk: (state) => {
      state.gapiLoaded = true;
      return state;
    },
    gapiLoadFail: (state) => {
      state.gapiLoaded = false;
      return state;
    },
    gcalSetCookieBlock: (state, { payload: { cookieBlock } }) => {
      state.gcalCookieBlock = cookieBlock;
      return state;
    },
    gcalAuthStateChanged: (state, { payload: { gcalIsSignedIn, user } }) => {
      state.gcalIsSignedIn = gcalIsSignedIn;
      state.gcalUser = user;
      return state;
    },
    gcalLoginReq: (state) => {
      state.gcalLoading = true;
      return state;
    },
    gcalLoginOk: (state, { payload: { user } }) => {
      state.gcalLoading = false;
      state.gcalUser = user;
      state.gcalIsSignedIn = true;
      return state;
    },
    gcalLoginFail: (state) => {
      state.gaipLoading = false;
      return state;
    },
    gcalLogoutReq: (state) => {
      state.gcalLoading = true;
      return state;
    },
    gcalLogoutOk: (state) => {
      state = initialState;
      return state;
    },
    gcalLogoutFail: (state) => {
      state.gaipLoading = false;
      return state;
    },
    gcalListCalendarsReq: (state) => {
      state.gcalLoading = true;
      return state;
    },
    gcalListCalendarsOk: (state, { payload: { calendars } }) => {
      state.gcalLoading = false;
      state.gcalCalendars = calendars;
      if (state.gcalTarget === "default") {
        state.gcalTarget = calendars?.[0]?.id;
      }
      return state;
    },
    gcalListCalendarsFail: (state) => {
      state.gcalLoading = false;
      return state;
    },
    gcalSetTargetCalendar: (state, { payload: { calendarId } }) => {
      state.gcalTarget = calendarId;
      return state;
    },
    gcalGetCalendarEventsReq: (state) => {
      state.gcalLoading = true;
      return state;
    },
    gcalGetCalendarEventsOk: (state, { payload: { events } }) => {
      state.gcalLoading = false;

      if (events?.length) {
        state.gcalCalendarEvents = events;
      }
      return state;
    },
    gcalGetCalendarEventsFail: (state) => {
      state.gcalLoading = false;
      return state;
    },
    gcalCreateCalendarEventReq: (state) => {
      state.gcalLoading = true;
      return state;
    },
    gcalCreateCalendarEventOk: (state, { payload: { event } }) => {
      state.gcalLoading = false;
      state.gcalCalendarEvents.push(event);
      return state;
    },
    gcalCreateCalendarEventFail: (state) => {
      state.gcalLoading = false;
      return state;
    },
  },
});

const { actions, reducer } = calendarSlice;

export const {
  msalAuthStateChanged,
  msalLoginReq,
  msalLoginOk,
  msalLoginFail,
  msalLogoutReq,
  msalLogoutOk,
  msalLogoutFail,
  msalGetCalendarEventsReq,
  msalGetCalendarEventsOk,
  msalGetCalendarEventsFail,
  msalCreateCalendarEventReq,
  msalCreateCalendarEventOk,
  msalCreateCalendarEventFail,
  msalListCalendarsReq,
  msalListCalendarsOk,
  msalListCalendarsFail,
  msalSetTargetCalendar,
  gapiLoadOk,
  gapiLoadFail,
  gcalAuthStateChanged,
  gcalLoginReq,
  gcalLoginOk,
  gcalLoginFail,
  gcalLogoutReq,
  gcalLogoutOk,
  gcalLogoutFail,
  gcalListCalendarsReq,
  gcalListCalendarsOk,
  gcalListCalendarsFail,
  gcalSetTargetCalendar,
  gcalGetCalendarEventsReq,
  gcalGetCalendarEventsOk,
  gcalGetCalendarEventsFail,
  gcalCreateCalendarEventReq,
  gcalCreateCalendarEventOk,
  gcalCreateCalendarEventFail,
  gcalSetCookieBlock,
} = actions;

export default reducer;
