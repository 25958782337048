import { combineEpics, ofType } from "redux-observable";
import { from, of, merge, scheduled } from "rxjs";
import {
  catchError,
  filter,
  mergeMap,
  concatMap,
  takeUntil,
  delay,
} from "rxjs/operators";

import { getEmitterEventObserver, getCommandsObserver } from "./jitsi.utils";

import {
  recordingStateChanged,
  startRecordingReq,
  stopRecordingReq,
  startRecordingOk,
  stopRecordingOk,
  startRecordingFail,
  stopRecordingFail,
} from "./recording.slice";

import {
  initializeRoomOk,
  conferenceDisconnect,
  connectionEstablished,
} from "./conference.slice";

import { RECORDING_CHANGED } from "./commands.constants";
import { parseRecordingSession } from "./recording.utils";
import JitsiMeetJS from "@lyno/lib-jitsi-meet";

/**
 * @description Initialize recording listeners in a room
 */
const recordingListenersEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(() => {
      const room = roomManager.getRoom();

      const changeRecorderStateObserver = getEmitterEventObserver(
        room,
        JitsiMeetJS.events.conference.RECORDER_STATE_CHANGED
      ).pipe(
        mergeMap((recordingSession) => {
          const parsedRecSession = parseRecordingSession(recordingSession);

          room.sendCommandOnce(RECORDING_CHANGED, {
            value: parsedRecSession,
            attributes: {
              id: parsedRecSession?.id,
              status: parsedRecSession?.status,
              mode: parsedRecSession?.mode,
            },
          });

          return [];
        })
      );

      return merge(changeRecorderStateObserver).pipe(
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Initialize recording listeners after entering a room
 */
const recordingListenerCommandsEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(connectionEstablished.match),
    mergeMap(() => {
      const room = roomManager.getRoom();

      const recordingChangedObservable = getCommandsObserver(
        room,
        RECORDING_CHANGED
      ).pipe(
        mergeMap((data) => {
          const recordingSession = {
            id: data[0]?.attributes?.id || null,
            status: data[0]?.attributes.status || null,
            mode: data[0]?.attributes?.mode || null,
          };

          return [recordingStateChanged({ recordingSession })];
        })
      );

      return merge(recordingChangedObservable).pipe(
        takeUntil(action$.ofType(conferenceDisconnect.toString()))
      );
    })
  );

/**
 * @description Start a new recording session
 */
const startRecordingEpic = (action$, _state$, { roomManager }) =>
  action$.pipe(
    filter(startRecordingReq.match),
    mergeMap(({ payload: { type, dropboxToken } }) => {
      if (!type && !dropboxToken) {
        return [startRecordingFail({ error: "No recipient" })];
      }

      const config =
        type === "dropbox"
          ? {
              mode: JitsiMeetJS.constants.recording.mode.FILE,
              appData: JSON.stringify({
                file_recording_metadata: {
                  upload_credentials: {
                    service_name: "dropbox",
                    token: dropboxToken?.access_token,
                  },
                },
              }),
            }
          : {
              mode: JitsiMeetJS.constants.recording.mode.FILE,
              appData: JSON.stringify({
                file_recording_metadata: {
                  share: true,
                },
              }),
            };

      const room = roomManager.getRoom();

      return from(room.startRecording(config)).pipe(
        mergeMap((data) => {
          /* console.log("rec start", data); */

          return [startRecordingOk()];
        }),
        catchError((error) => {
          console.log(error);
          return [startRecordingFail({ error })];
        })
      );
    })
  );

/**
 * @description Stop an active recording session
 */
const stopRecordingEpic = (action$, state$, { roomManager }) =>
  action$.pipe(
    filter(stopRecordingReq.match),
    mergeMap(() => {
      const room = roomManager.getRoom();
      const recordingSessionId =
        state$.value.recordingReducer?.recordingSession?.id;

      return from(room.stopRecording(recordingSessionId)).pipe(
        mergeMap((data) => {
          /* console.log("rec stop", data); */

          return [stopRecordingOk()];
        }),
        catchError((error) => [stopRecordingFail({ error })])
      );
    })
  );

export const recordingEpic = combineEpics(
  recordingListenersEpic,
  startRecordingEpic,
  stopRecordingEpic,
  recordingListenerCommandsEpic
);
